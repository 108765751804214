import React, { useState } from 'react'
import './DeleteAccount.css'

import { FaRegWindowClose } from 'react-icons/fa'
import { useDispatch } from 'react-redux'

import { setToggleDeleteAccount } from '../../../reducers/userSlice'

const DeleteAccount = ({
    handleDeleteAccount,
}) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch()

  return (
    <div className='delete-account'>
        <form>                                              
            <h2>Re-Login:</h2>
            <FaRegWindowClose onClick={() => dispatch(setToggleDeleteAccount(false))}/>
          {/* <label htmlFor="email-address">Email address</label> */}
          <input
              id="email-address"
              name="email"
              type="email"                                    
              required                                                                                
              placeholder="Email address"
              onChange={(e)=>setEmail(e.target.value)}
              value={email}
          />

          {/* <label htmlFor="password">Password</label> */}
          <input
              id="password"
              name="password"
              type="password"                                    
              required                                                                                
              placeholder="Password"
              onChange={(e)=>setPassword(e.target.value)}
              value={password}
          />
          <button className='delete-account-button' onClick={(e) => handleDeleteAccount(e, email, password)}>Delete Account</button>
        </form>
    </div>
  )
}

export default DeleteAccount