import React, { useEffect, useState } from 'react'
import './ManageRoll.css'

import { FaTrashCan } from 'react-icons/fa6'
import { FaEdit } from 'react-icons/fa'
import { useSearchParams } from 'react-router-dom'

import PhotoModal from '../SelectPhoto/PhotoModal/PhotoModal'
import EditPhoto from '../SelectPhoto/EditPhoto/EditPhoto'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentCameraId, setCurrentCameraId } from '../../reducers/cameraSlice'
import { selectCurrentPhotoId, selectToggleDeletePhoto, selectToggleEditPhoto, selectTogglePhotoModal, setCurrentPhotoId, setToggleDeletePhoto, setToggleEditPhoto, setTogglePhotoModal } from '../../reducers/photoSlice'
import { selectCurrentRollId, setCurrentRollId } from '../../reducers/rollSlice'
import { deletePhotoFromRoll, selectData } from '../../reducers/AppSlice'
import { selectIsAdmin, selectUserId } from '../../reducers/userSlice'
import { formatRollName } from '../../helpers'

const ManageRoll = ({ 
    handleChangeRollAndNavigate, 
    handleMessage,
    handleChangePhotoUrls,
    handleUploadImage
}) => {

    const data = useSelector(selectData)
    const userId = useSelector(selectUserId)
    const isAdmin = useSelector(selectIsAdmin)
    const currentCameraId = useSelector(selectCurrentCameraId)
    const toggleEditPhoto = useSelector(selectToggleEditPhoto)
    const togglePhotoModal = useSelector(selectTogglePhotoModal)
    const currentRollId = useSelector(selectCurrentRollId)
    const toggleDeletePhoto = useSelector(selectToggleDeletePhoto)
    const currentPhotoId = useSelector(selectCurrentPhotoId)

    const [localCameraData, setLocalCameraData] = useState({})
    const [localData, setLocalData] = useState()
    const [nameState, setNameState] = useState('')
    const [dateState, setDateState] = useState('')

    const [defaultCameraValue, setDefaultCameraValue] = useState('')
    const [defaultRollValue, setDefaultRollValue] = useState('')
   
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch()
    
    // ==============GET SEARCH PARAMS==================== //
  useEffect(() => {
        const cameraId = searchParams.get('camera_id')
        const rollId = searchParams.get('roll_id')
    
        if (cameraId) {
            dispatch(setCurrentCameraId(Number(cameraId)))
        }

        if(rollId) {
            dispatch(setCurrentRollId(Number(rollId)))
        }

        if (data) {
            data.forEach(camera=> {
                if (camera.id === currentCameraId) {
                    setLocalCameraData(camera)
                    camera.film_rolls.forEach(roll => {
                        if (roll.id === currentRollId) {
                            setLocalData(roll)
                        }
                    })
                }
            })
            if (localData && localData.name) {
                const arr = localData.name.split("_")
                let name =''
                arr.forEach(word => {
                    const s = word.charAt(0).toUpperCase() + word.slice(1) + " "
                    name += s
                })
                setNameState(name)
                // const dateObject = new Date(localData.date_created.seconds * 1000)
                // const dateCreated = dateObject.toLocaleString('en-US', {
                //     year: "numeric",
                //     month: "numeric",
                //     day: "numeric",
                // }) 
                const dateCreated = localData.date_created
                setDateState(dateCreated)
            }
        }
    },[data, currentRollId, localData, searchParams, currentCameraId, dispatch])

    useEffect(() => {
        const getDefaultCameraValue = () => {
          if (data) {
            data.forEach(camera => {
              if (camera.id === currentCameraId) {
                setDefaultCameraValue(JSON.stringify(camera))
                camera.film_rolls.forEach(roll => {
                  if (roll.id === currentRollId) {
                    setDefaultRollValue(JSON.stringify(roll))
                  }
                })
              } 
            })
          }
        }
        getDefaultCameraValue()
      },[data, setDefaultCameraValue, currentCameraId, currentRollId, setDefaultRollValue])

  return (
    <div className='manage-roll'>
        <div>
            <div
            className={`edit-photo-container ${toggleEditPhoto ? 'active' : ''}`}
            onClick={() => {
                dispatch(setToggleEditPhoto(false))
            }}
            >
            </div>
            <EditPhoto  
                localData={localData}
                handleMessage={handleMessage}
                handleUploadImage={handleUploadImage}
            />
        </div>
        <select 
            onChange={(e) => {
                    const temp = JSON.parse(e.target.value)
                    dispatch(setCurrentCameraId(temp.id))
                    dispatch(setCurrentRollId(temp.film_rolls[0].id))
                    handleChangeRollAndNavigate('/manage-roll', temp.id, temp.film_rolls[0].id)
                }
            }
            value={defaultCameraValue}
        >
        {data ? (
          data.map(camera => {
            return (
              <option key={camera.id} value={JSON.stringify(camera)}>
                {formatRollName ? formatRollName(camera, 'user') :''}
                {/* {formatDate ? formatDate(roll) : ''} */}
              </option>
            )
          })
        ) : ''}
        </select>

        <select 
            onChange={(e) => {
                const temp = JSON.parse(e.target.value)
                dispatch(setCurrentRollId(temp.id))
                setLocalData(temp)
                handleChangeRollAndNavigate('/manage-roll', currentCameraId, temp.id)
            }
            }
            value={defaultRollValue}
        >
            {localCameraData.film_rolls ? (
            localCameraData.film_rolls.map(roll => {
                return (
                <option key={roll.id} value={JSON.stringify(roll)}>
                    {formatRollName ? formatRollName(roll, 'user') : ''}
                </option>
                )
            })
            ) : ''}
            
        </select>
        <div className='roll'>
            <div className='roll-header'>
                <h2>{nameState}</h2>
                <h6>{dateState}</h6>
                {isAdmin ? (
                    <button onClick={() => handleChangePhotoUrls(currentRollId)}>Update Photo URLs</button>
                ) : ''}
            </div>
            <PhotoModal 
                handleUploadImage={handleUploadImage}
            />
            <ul>
                {
                    localData ? (
                        localData.photos.map(photo => {
                            return (
                                <li 
                                    key={photo.id} 
                                    className='roll-photo-settings'
                                >
                                    <div 
                                        className='photo-specs'
                                        onClick={() => {
                                            dispatch(setTogglePhotoModal(!togglePhotoModal))
                                            dispatch(setCurrentPhotoId(Number(photo.id)))
                                        }}
                                    >
                                        <h3>{photo.subject}</h3>
                                        {/* <img src={photo.url} alt=''/> */}
                                        <h4>{photo.lens}</h4>
                                        <h4>F{photo.f_stop}</h4>
                                        <h4>{photo.shutter_speed}s</h4>
                                        <h4>{photo.exposure_comp} EF</h4>
                                    </div>
                                    <div className='photo-manage'>
                                        <FaEdit 
                                            size ='30' 
                                            onClick={() => {
                                                dispatch(setToggleEditPhoto(!toggleEditPhoto))
                                                dispatch(setCurrentPhotoId(photo.id))
                                            }}/>
                                        <FaTrashCan 
                                            size='30' 
                                            className='delete-photo-trash-can'
                                            onClick={() => {
                                                dispatch(setToggleDeletePhoto(true))
                                                dispatch(setCurrentPhotoId(photo.id))
                                            }
                                        }/>
                                        
                                    </div>
                                </li>
                            )
                        })
                    ) : ''
                }
            </ul>
        </div>
        {toggleDeletePhoto ? (
        <div>
          <div 
            className={`delete-photo-container ${toggleDeletePhoto ? 'active': ''}`}
            onClick={() => {
              dispatch(setToggleDeletePhoto(false))
            }}
          ></div>
        <div className={`toggle-delete-photo-container ${toggleDeletePhoto ? 'active' : ''}`}>
            <p>Are you sure you want to delete this photo?</p>
            <span>
                <button
                    onClick={() => {
                        dispatch(deletePhotoFromRoll({
                            userId: userId,
                            cameraId: currentCameraId,
                            rollId: currentRollId,
                            photoId: currentPhotoId
                        }))
                        dispatch(setToggleDeletePhoto(false))
                    }}
                >Yes</button>
                <button
                    onClick={() => {
                        dispatch(setToggleDeletePhoto(false))
                    }}
                >No</button>
            </span>
        </div>
        </div>
      ) : ''}
    </div>
  )
}

export default ManageRoll