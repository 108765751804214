import React from 'react'
import './Settings.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAdmin, setToggleDeleteAccount } from '../../reducers/userSlice'
import { selectCurrentCameraId } from '../../reducers/cameraSlice'
import { selectCurrentRollId } from '../../reducers/rollSlice'

const Settings = ({
    handleChangeRollAndNavigate,
    handleSignout,
    handleCreateDBBackup,
}) => {
  
  const isAdmin = useSelector(selectIsAdmin)
  const currentCameraId = useSelector(selectCurrentCameraId)
  const currentRollId = useSelector(selectCurrentRollId)

  const dispatch = useDispatch()

  return (
    <div className='settings'>
      <section>
        <h3>Settings</h3>
        <button onClick={() => {
            handleChangeRollAndNavigate('/manage-roll', currentCameraId, currentRollId)
        }}>Manage Photos</button>
        {/* <button onClick={() => {
            handleChangeRollAndNavigate('/manage-roll', currentCameraId, currentRollId)
        }}>Manage Lenses</button> */}
        {isAdmin === true ? (
          <button onClick={() => {
              handleCreateDBBackup()
            }}
          >Backup DB</button>
        ) : ''}
        <button onClick={() => handleSignout()} >Sign Out</button>
      </section>
        <div className='danger-section'>
            <h5>Danger Section</h5>
            <button onClick={() => dispatch(setToggleDeleteAccount(true))}>Delete Account</button>
        </div>
    </div>
  )
}

export default Settings