import { configureStore } from '@reduxjs/toolkit'

import appReducer from '../reducers/AppSlice'
import userReducer from '../reducers/userSlice'
import cameraReducer from '../reducers/cameraSlice'
import messageReducer from '../reducers/messageSlice'
import photoReducer from '../reducers/photoSlice'
import rollReducer from '../reducers/rollSlice'

export const store = configureStore({
    reducer: {
        app: appReducer,
        user: userReducer,
        camera: cameraReducer,
        message: messageReducer,
        photo: photoReducer,
        roll: rollReducer,
    },
})

