import React, { useEffect, useRef, useState } from 'react'
import './PhotoModal.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentCameraId } from '../../../reducers/cameraSlice'
import { 
  selectCurrentPhotoId, 
  selectTogglePhotoModal, 
  selectUploadProgress, 
  setCurrentPhotoId,
  setTogglePhotoModal,
  setUploadProgress, 
  // setToggleEditPhoto, 
  // setTogglePhotoModal 
} from '../../../reducers/photoSlice'
import { selectCurrentRollId } from '../../../reducers/rollSlice'
import { selectData } from '../../../reducers/AppSlice'
// import { FaCheck, FaChevronLeft, FaChevronRight } from 'react-icons/fa6'
// import { FaEdit } from 'react-icons/fa'
import { useSwipeable } from 'react-swipeable'
import { FaWindowClose } from 'react-icons/fa'

// import { FaRegWindowClose } from 'react-icons/fa'

const PhotoModal = ({
  handleUploadImage,
}) => {

  const data = useSelector(selectData)
  const currentCameraId = useSelector(selectCurrentCameraId)
  const currentPhotoId = useSelector(selectCurrentPhotoId)
  const togglePhotoModal = useSelector(selectTogglePhotoModal)
  const currentRollId = useSelector(selectCurrentRollId)
  const uploadProgress = useSelector(selectUploadProgress)

  const [localRollData, setLocalRollData] = useState()
  const [index, setIndex] = useState(0)
  const [imageUpload, setImageUpload] = useState(null)

  const hiddenFileInput = useRef(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (data) {
      data.forEach(camera => {
        if (camera.id === currentCameraId) {
          camera.film_rolls.forEach(roll => {
            if (roll.id === currentRollId) {
              setLocalRollData(roll)
              roll.photos.forEach(photo => {
                if (photo.id === currentPhotoId) {
                  setIndex(roll.photos.indexOf(photo))
                }
              })
            }
          })
        }
      })
    }
  },[data, currentRollId, currentCameraId, currentPhotoId])

  // useEffect(() => {
  //   setImageUpload(null)
  // },[setImageUpload])

  const handlers = useSwipeable({
    // onSwiped: (eventData) => console.log("User Swiped!", eventData),
    onSwipedLeft: () => {
      if (localRollData.photos[index + 1]) {
        dispatch(setCurrentPhotoId(localRollData.photos[index + 1].id))
        let num = index + 1
        setIndex(num)
      }
      setImageUpload(null)
      dispatch(setUploadProgress(0))
    },
    onSwipedRight: () => {
      if (localRollData.photos[index - 1]) {
        dispatch(setCurrentPhotoId(localRollData.photos[index - 1].id))
        let num = index - 1
        setIndex(num)
      }
      setImageUpload(null)
      dispatch(setUploadProgress(0))
    },
    onSwipedDown: () => {
      dispatch(setTogglePhotoModal(false))
      setImageUpload(null)
      dispatch(setUploadProgress(0))
    },
    onSwipedUp: () => {
      dispatch(setTogglePhotoModal(false))
      setImageUpload(null)
      dispatch(setUploadProgress(0))
    },
    preventScrollOnSwipe: true,
  });
  
  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  return (
    <div className={`photo-modal ${togglePhotoModal ? 'active' : ''}`}>
      <FaWindowClose 
        onClick={() => dispatch(setTogglePhotoModal(false))}
        style={{
          visibility: `${togglePhotoModal ? 'visible' : 'hidden'}`
        }}
      />
        {localRollData ? (
          localRollData.photos.map(photo => {
            if(photo.id === currentPhotoId) {
              // setIndex(localRollData.photos.indexOf(photo))
              return(
                <div 
                  key={photo.id}
                  style={{
                    backgroundColor: 'var(--main-black)',
                  }}
                >
                  <div className='photo-modal-photo-container'>
                    <img 
                      src={photo.url} 
                      alt=''
                      {...handlers}
                      />
                      {uploadProgress === 100 ? (
                        <p>Upload Complete!</p>
                      ) : (
                        <div>
                          <div 
                            className='upload-progress'
                            style={{
                              width: `${uploadProgress}%`,
                              height: '0.5rem',
                              backgroundColor: 'green',
                            }}
                          >
                          </div>
                          {imageUpload !== null ? (
                            <p>File: {imageUpload.name}</p>
                          ) : ''}
                        </div>

                      )}
                      {photo.url.toLowerCase().includes('default') ? (
                        <div className='image-upload-container'>
                          <input 
                            type="file"
                            onChange={(e) => {
                              setImageUpload(e.target.files[0])
                            }}
                            ref={hiddenFileInput}
                            style={{display: 'none'}}
                          />
                          <button onClick={handleClick}>Select Upload</button>
                          <button onClick={() => {
                            handleUploadImage(imageUpload)
                            setImageUpload(null)
                          }}>Upload Image</button>
                        </div>
  
                      ) : ''}

                  </div>
                  <div>
                    <div className='photo-modal-photo-settings'>
                      
                      <div>
                        <h3>{photo.subject}</h3>
                        <h4>{photo.date_created}</h4>
                      </div>
                      <p>F{photo.f_stop} | {photo.shutter_speed}s | {photo.exposure_comp}X EF | {photo.shooting_mode}</p>
                      
                    </div>

                  </div>
                </div>
              )
            } else {
              return ''
            }
          })
        ) : ''}
    </div>
  )
}

export default PhotoModal