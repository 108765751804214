  import React, { useEffect } from 'react'
  import './Navbar.css'

  import { MdOutlineCameraRoll, MdOutlineInsertPhoto,MdOutlineSettings } from 'react-icons/md'
  import { FaPlus } from 'react-icons/fa'
  import { useDispatch, useSelector } from 'react-redux'
  import { selectCurrentCameraId, setAddCameraActive } from '../../reducers/cameraSlice'
  import { setAddPhotoActive } from '../../reducers/photoSlice'
  import { selectCurrentRollId, setAddRollActive } from '../../reducers/rollSlice'
import { useLocation } from 'react-router-dom'


  const Navbar = ({ 
    handleChangeRollAndNavigate, 
    toggleAdd, 
    setToggleAdd,
    activeIcon, 
    setActiveIcon
  }) => {

    

    const size='30'

    const currentCameraId = useSelector(selectCurrentCameraId)
    const currentRollId = useSelector(selectCurrentRollId)

    const dispatch = useDispatch()
    
    const currentLocation = useLocation()
    
    useEffect(() => {

      switch(currentLocation.pathname) {
        case '/select-roll':
          setActiveIcon('cameraRoll')
          break;
        case '/select-photo':
          setActiveIcon('selectPhoto')
          break;
        case '/settings':
          setActiveIcon('settings')
          break;
        case '/manage-roll':
          setActiveIcon('settings')
          break;
        default:
          setActiveIcon('')
          break;
      }
    },[currentLocation.pathname, setActiveIcon])


    return (
      <footer>
          
          <div 
            className={activeIcon === 'cameraRoll'? 'select-roll-icon active' : 'select-roll-icon'}
            onClick={() => {
              handleChangeRollAndNavigate(`/select-roll`, currentCameraId, currentRollId)
              setActiveIcon('cameraRoll')
            }}
          >
            <MdOutlineCameraRoll size={size}/>  
          </div>
          <div 
            className={activeIcon === 'selectPhoto' ? 'select-photo-icon active' : 'select-photo-icon'}
            onClick={() => {
              handleChangeRollAndNavigate(`/select-photo`, currentCameraId, currentRollId)
              setActiveIcon('selectPhoto')
            }}
          >
            <MdOutlineInsertPhoto size={size}/>
          </div>
          
          <div 
            className='plus-icon'
            onClick={() => setToggleAdd(!toggleAdd)}
          >
            <FaPlus size={size}/>
          </div>
          
          <div 
            className={activeIcon === 'settings' ? 'settings-icon active' : 'settings-icon'}
            onClick={() => {
              handleChangeRollAndNavigate(`/settings`, currentCameraId, currentRollId)
              setActiveIcon('settings')
            }}  
          >
            <MdOutlineSettings size={size}/>
          </div>
          {toggleAdd ? (
            <div style={{position: 'absolute'}}>
              <div 
                className={`add-modal-container ${toggleAdd ? 'active': ''}`}
                onClick={() => {
                  setToggleAdd(false)
                }}  
              >

              </div>
              <div className='add-modal'>
                <button 
                  onClick={() => {
                    dispatch(setAddCameraActive(true))
                    setToggleAdd(!toggleAdd)
                  }
                }>Add Camera</button>
                <button 
                  onClick={() => {
                    dispatch(setAddRollActive(true))
                    setToggleAdd(!toggleAdd)
                  }
                }>Add Roll</button>
                <button 
                  onClick={() => {
                    dispatch(setAddPhotoActive(true))
                    setToggleAdd(!toggleAdd)
                  }
                }>Add Photo</button>
              </div>
            </div>
          ) : ''}
      </footer>
    )
  }

  export default Navbar