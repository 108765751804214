import React, { useState } from 'react'
import './AddLens.css'

import { useDispatch, useSelector } from 'react-redux'

import { FaRegWindowClose } from 'react-icons/fa'

import { addLensToCamera } from '../../reducers/AppSlice'
import { selectUserId } from '../../reducers/userSlice'
import { selectCurrentCameraId, setAddLensActive } from '../../reducers/cameraSlice'

const AddLens = ({
    handleMessage,
}) => {

    const [lensName, setLensName] = useState('')

    const userId = useSelector(selectUserId)
    const cameraId = useSelector(selectCurrentCameraId)

    const dispatch = useDispatch()

  return (
    <div className='add-lens'>
        
        <form onSubmit={(e) => {
            e.preventDefault()
            handleMessage('Lens Added!')

            dispatch(addLensToCamera({
                userId: userId,
                cameraId: cameraId,
                lensData: {
                    name: lensName
                }
            }))
            dispatch(setAddLensActive(false))
        }}>
            <FaRegWindowClose onClick={() => dispatch(setAddLensActive(false))}/>
            <label htmlFor='lens-name'>Lens:</label>
            <input 
                type='text'
                name='lens-name'
                id='lens-name'
                value={lensName}
                placeholder='Lens Name'
                onChange={(e) => setLensName(e.target.value)}
            />

            <input 
                type='submit'
                value='Add Lens'
            />
        </form>
    </div>
  )
}

export default AddLens