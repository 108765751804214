import React from 'react'
import './SuccessMessage.css'
import { useSelector } from 'react-redux'
import { selectMessage } from '../../reducers/messageSlice'

const SuccessMessage = () => {

  const message = useSelector(selectMessage)

  return (
    <div className='success-message'>
        <b>Success!</b><p>{message}</p>
    </div>
  )
}

export default SuccessMessage