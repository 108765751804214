import React, { useState } from 'react'
import './CameraRollList.css'

import { selectAddLensActive, setAddLensActive, setCurrentCameraId } from '../../../reducers/cameraSlice'
import { useDispatch, useSelector } from 'react-redux'
import { 
    selectAddRollActive, 
    setAddRollActive, 
} from '../../../reducers/rollSlice'
import RollListItem from './RollListItem/RollListItem'
import { formatRollName } from '../../../helpers'
import { FaEllipsisH } from 'react-icons/fa'
import { FaChevronRight } from 'react-icons/fa6'


const CameraRollList = ({
    camera,
    localCurrentRollId,
    setLocalCurrentRollId,
    handleChangeRollAndNavigate,
    setActiveIcon
}) => {

    
    const addLensActive = useSelector(selectAddLensActive)
    const addRollActive = useSelector(selectAddRollActive)

    const [toggleShowRollList, setToggleShowRollList] = useState(true)
    const [toggleShowAddButtons, setToggleShowAddButtons] = useState(false)

    const dispatch = useDispatch()

  return (
    <section key={camera.id} className='camera-rolls-list'>
        <div 
            className={`camera-header ${camera.name === "Loading..." ? 'loading' : ''}`}
            onClick={() => setToggleShowRollList(!toggleShowRollList)}  
            >
            <h2>{formatRollName(camera, 'user')}</h2>
            <span className={toggleShowRollList ? 'active' : ''}>{<FaChevronRight />}</span>
        </div>
        <div className={`active-div ${toggleShowRollList? 'active' : ''}`}>
            {camera.name !== 'Loading...' ? (
                <div className='ellipse-container'>
                    <FaEllipsisH 
                        size ='20' 
                        onClick={() => setToggleShowAddButtons(!toggleShowAddButtons)}
                    />
                </div>
            ) : ''}
            <div className={`add-buttons ${toggleShowAddButtons ? 'active' : ''}`}>
                <button 
                    onClick={() => {
                        dispatch(setAddRollActive(!addRollActive))
                        dispatch(setCurrentCameraId(camera.id))
                    }}
                >Add Roll</button>
                <button 
                    onClick={() => {
                        dispatch(setAddLensActive(!addLensActive))
                        dispatch(setCurrentCameraId(camera.id))
                    }}
                >Add Lens</button>
            </div>
            <ul>
                {camera.film_rolls
                    .slice()
                    .sort((a,b) => {
                        const dateA = new Date(a.date_created)
                        const dateB = new Date(b.date_created)

                        return dateB - dateA
                    })
                    .sort((a,b) => {
                        const dateA = new Date(a.last_modified)
                        const dateB = new Date(b.last_modified)

                        return dateB - dateA
                    })
                    .map(roll => {
                        return (
                            <RollListItem 
                                key={roll.id}
                                camera={camera}
                                roll={roll}
                                localCurrentRollId={localCurrentRollId}
                                setLocalCurrentRollId={setLocalCurrentRollId}
                                handleChangeRollAndNavigate={handleChangeRollAndNavigate}
                                setActiveIcon={setActiveIcon}
                            />
                        )
                })}

            </ul>
        </div>
        </section>
  )
}

export default CameraRollList