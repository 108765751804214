import { createSlice } from "@reduxjs/toolkit"
import { arrayUnion, doc, updateDoc } from "firebase/firestore"
import { db } from "../firebaseConfig"
import { createDateString } from "../helpers"

const initialState = {
    data: [   
        {
            "shooting_modes": [],
            "__deleted_photos": [],
            "last_modified": "September 28, 2023",
            "__deleted_rolls": [],
            "test_data": [],
            "film_rolls": [
                {
                    "date_created": "August 2, 2023",
                    "name": "Loading...",
                    "photos": [
                        {
                            "url": "",
                            "lens": "",
                            "id": 216,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                        {
                            "url": "",
                            "lens": "",
                            "id": 2,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                        {
                            "url": "",
                            "lens": "",
                            "id": 3,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                    ],
                    "last_modified": "September 11, 2023",
                    "id": 74532,
                    "exposures": "36"
                },
                {
                    "date_created": "August 2, 2023",
                    "name": "Loading...",
                    "photos": [
                        {
                            "url": "",
                            "lens": "",
                            "id": 216,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                        {
                            "url": "",
                            "lens": "",
                            "id": 2,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                        {
                            "url": "",
                            "lens": "",
                            "id": 3,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                    ],
                    "last_modified": "September 11, 2023",
                    "id": 2354,
                    "exposures": "36"
                },
                
                
            ],
            "id": 95807,
            "lenses": [],
            "name": "Loading...",
            "date_created": "August 2, 2023"
        },
        {
            "shooting_modes": [],
            "__deleted_photos": [],
            "last_modified": "September 28, 2023",
            "__deleted_rolls": [],
            "test_data": [],
            "film_rolls": [
                {
                    "date_created": "August 2, 2023",
                    "name": "Loading...",
                    "photos": [
                        {
                            "url": "",
                            "lens": "",
                            "id": 216,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                        {
                            "url": "",
                            "lens": "",
                            "id": 2,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                        {
                            "url": "",
                            "lens": "",
                            "id": 3,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                    ],
                    "last_modified": "September 11, 2023",
                    "id": 74532,
                    "exposures": "36"
                },
                {
                    "date_created": "August 2, 2023",
                    "name": "Loading...",
                    "photos": [
                        {
                            "url": "",
                            "lens": "",
                            "id": 216,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                        {
                            "url": "",
                            "lens": "",
                            "id": 2,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                        {
                            "url": "",
                            "lens": "",
                            "id": 3,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                    ],
                    "last_modified": "September 11, 2023",
                    "id": 2354,
                    "exposures": "36"
                },
                
                
            ],
            "id": 2456254,
            "lenses": [],
            "name": "Loading...",
            "date_created": "August 2, 2023"
        },
        {
            "shooting_modes": [],
            "__deleted_photos": [],
            "last_modified": "September 28, 2023",
            "__deleted_rolls": [],
            "test_data": [],
            "film_rolls": [
                {
                    "date_created": "August 2, 2023",
                    "name": "Loading...",
                    "photos": [
                        {
                            "url": "",
                            "lens": "",
                            "id": 216,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                        {
                            "url": "",
                            "lens": "",
                            "id": 2,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                        {
                            "url": "",
                            "lens": "",
                            "id": 3,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                    ],
                    "last_modified": "September 11, 2023",
                    "id": 74532,
                    "exposures": "36"
                },
                {
                    "date_created": "August 2, 2023",
                    "name": "Loading...",
                    "photos": [
                        {
                            "url": "",
                            "lens": "",
                            "id": 216,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                        {
                            "url": "",
                            "lens": "",
                            "id": 2,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                        {
                            "url": "",
                            "lens": "",
                            "id": 3,
                            "shooting_mode": "Auto",
                            "shutter_speed": "1/2000",
                            "f_stop": 2,
                            "subject": "Loading...",
                            "exposure_comp": 1,
                            "date_created": "August 2, 2023"
                        },
                    ],
                    "last_modified": "September 11, 2023",
                    "id": 2354,
                    "exposures": "36"
                },
                
                
            ],
            "id": 3263567,
            "lenses": [],
            "name": "Loading...",
            "date_created": "August 2, 2023"
        },
    ],
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        addNewCamera: (state, action) => {
            const {userId, cameraData} = action.payload
            const docRef = doc(db, 'users', userId)
            const updateDB = async () => {
                await updateDoc(docRef, {
                    'cameras': arrayUnion(cameraData)
                })
            }
            updateDB()
        },
        addRolltoCamera: (state, action) => {
            const { userId, cameraId, rollData } = action.payload
            state.data.forEach(camera => {
                if (camera.id === cameraId) {
                    camera.film_rolls.push(rollData)
                }
            })
            const tempData = [...state.data]
            const updateDB = async () => {
                const docRef = doc(db, 'users', userId)
                await updateDoc(docRef, {
                    'cameras': tempData
                })
            }
            updateDB()
        },
        addLensToCamera: (state, action) => {
            const { userId, cameraId, lensData } = action.payload
            state.data.forEach(camera => {
                if (camera.id === cameraId) {
                    camera.lenses.push(lensData.name)
                }
            })
            const tempData = [...state.data] 
            const updateDB = async () => {
                const docRef = doc(db, 'users', userId)
                await updateDoc(docRef, {
                    'cameras': tempData
                })
            }
            updateDB()
        },
        deleteRollFromCamera: (state, action) => {
            const { userId, cameraId, rollId } = action.payload
            state.data.forEach(camera => {
                if (camera.id === cameraId) {
                    camera.film_rolls.forEach(roll => {
                        if (roll.id === rollId) {
                            roll = {...roll, 'date_deleted': createDateString()}
                            camera.__deleted_rolls.push(roll)
                            const index = camera.film_rolls.indexOf(roll)
                            camera.film_rolls.splice(index, 1)
                        }
                    })
                }
            })
            const tempData = [...state.data]
            const updateDB = async () => {
                const docRef = doc(db, 'users', userId)
                await updateDoc(docRef, {
                    'cameras': tempData
                })
            }
            updateDB()
        },
        editRoll: (state, action) => {
            const { userId, cameraId, rollData } = action.payload
            state.data.forEach(camera => {
                if (camera.id === cameraId) {
                    camera.film_rolls = camera.film_rolls.map(roll => roll.id === rollData.id ? rollData : roll)
                }
            })
            const tempData = [...state.data]
            const updateDB = async () => {
                const docRef = doc(db, 'users', userId)
                await updateDoc(docRef, {
                    'cameras': tempData
                })
            }
            updateDB()
        },
        addPhotoToRoll: (state, action) => {
            const { userId, cameraId, rollId, photoData } = action.payload
            state.data.forEach(camera => {
                if (camera.id === cameraId) {
                    camera.last_modified = createDateString()
                    camera.film_rolls.forEach(roll => {
                        if (roll.id === rollId) {
                            roll.last_modified = createDateString()
                            roll.photos.push(photoData)
                        }
                    })
                }
            })
            const tempData = [...state.data]
            const updateDB = async () => {
                const docRef = doc(db, 'users', userId)
                await updateDoc(docRef, {
                    'cameras': tempData
                })
            }
            updateDB()
        },
        deletePhotoFromRoll: (state, action) => {
            const { userId, cameraId, rollId, photoId } = action.payload
            state.data.forEach(camera => {
                if (camera.id === cameraId) {
                    camera.film_rolls.forEach(roll => {
                        if (roll.id === rollId) {
                            roll.photos.forEach(photo => {
                                if (photo.id === photoId) {
                                    camera.__deleted_photos.push({...photo, 'date_deleted': createDateString()})
                                    const index = roll.photos.indexOf(photo) 
                                    roll.photos.splice(index, 1)
                                }
                            })
                        }
                    })
                }
            })
            const tempData = [...state.data] 
            const updateDB = async () => {
                const docRef = doc(db, 'users', userId) 
                await updateDoc(docRef, {
                    'cameras': tempData
                })
            }
            updateDB()
        },
        editPhoto: (state, action) => {
            const { userId, cameraId, rollId, photoData } = action.payload
            state.data.forEach(camera => {
                if (camera.id === cameraId) {
                    camera.film_rolls.forEach(roll => {
                        if (roll.id === rollId) {
                            roll.photos = roll.photos.map(photo => photo.id === photoData.id ? photoData : photo)

                        }
                    })
                }
            })
            const tempData = [...state.data]
            const updateDB = async () => {
                const docRef = doc(db, 'users', userId)
                await updateDoc(docRef, {
                    'cameras': tempData
                })
            }
            updateDB()
        },
    }
})

export const { 
    setData,
    addNewCamera, 
    addRolltoCamera,
    addLensToCamera,
    addPhotoToRoll,
    editRoll,
    deleteRollFromCamera,
    deletePhotoFromRoll,
    editPhoto, 
} = appSlice.actions

export const selectData = (state) => state.app.data

export default appSlice.reducer