import React, { useEffect, useState } from 'react'

import './SelectRoll.css'

import EditRoll from './EditRoll/EditRoll'
import CameraRollList from './CameraRollList/CameraRollList'
import { useDispatch, useSelector } from 'react-redux'
import { setUserId } from '../../reducers/userSlice'
import { selectData } from '../../reducers/AppSlice'
import { selectToggleEditRoll, setToggleEditRoll } from '../../reducers/rollSlice'


const SelectRoll = ({ 
  handleChangeRollAndNavigate,
  handleMessage,
  setActiveIcon,
}) => {

  const data = useSelector(selectData)
  const toggleEditRoll = useSelector(selectToggleEditRoll)

  const [localCurrentRollId, setLocalCurrentRollId] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {

    const localFetch = async () => {
      const localUserDataFetch = JSON.parse(localStorage.getItem('user'))
  
      if(localUserDataFetch) {
        dispatch(setUserId(localUserDataFetch.uid))

      }

    }
    localFetch()
  },[dispatch])

  return (
    <div className='select-roll-master-container'>
      <div>
        <div
          className={`edit-roll-container ${toggleEditRoll ? 'active' : ''}`}
          onClick={() => {
            dispatch(setToggleEditRoll(false))
          }}
        ></div>
        <EditRoll 
          handleMessage={handleMessage}
        />
      </div>
      <ul className='select-roll'>
        { data ? (
          data.length > 0 ? (
            data ? (
              data
                .slice()
                .sort((a,b) => {
                  const dateA = new Date(a.last_modified)
                  const dateB = new Date(b.last_modified)

                  return dateB - dateA
                })
                .map(camera => {
                  return (
                    <CameraRollList 
                      key={camera.id}
                      camera={camera}
                      setLocalCurrentRollId={setLocalCurrentRollId}
                      localCurrentRollId={localCurrentRollId}
                      handleChangeRollAndNavigate={handleChangeRollAndNavigate}
                      setActiveIcon={setActiveIcon}
                    />
                  )
                })
                ) : ''
            ) : (
              <p>Nothing to show, add new camera by clicking the '+' button below!</p>
            )
          ) : (
            <p>loading...</p>
          )
        }
      </ul>
      
    </div>
  )
}

export default SelectRoll