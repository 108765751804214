import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './AddCamera.css'

import { FaRegWindowClose } from 'react-icons/fa'
import { TagsInput } from 'react-tag-input-component'

import { setAddCameraActive } from '../../reducers/cameraSlice'
import { addNewCamera } from '../../reducers/AppSlice'
import { selectUserId } from '../../reducers/userSlice'

import { createDateString, generateId } from '../../helpers'

const AddCamera = ({
    handleMessage,
}) => {

    const [cameraName, setCameraName] = useState('')
    const [lenses, setLenses] = useState([])
    const [shootingModes, setShootingModes] = useState([])

    const userId = useSelector(selectUserId)
    
    const dispatch = useDispatch()

    return (
        <div className='add-camera'>
            <form onSubmit={(e) => {
                e.preventDefault()
                
                dispatch(addNewCamera({
                    userId: userId,
                    cameraData: {
                        __deleted_photos: [],
                        __deleted_rolls: [],
                        film_rolls: [],
                        id: generateId(),
                        date_created: createDateString(),
                        name: cameraName.toLowerCase().replace(/ /g,"_").trim(),
                        test_data: [],
                        lenses: lenses,
                        shooting_modes: shootingModes
                    }
                }))
                dispatch(setAddCameraActive(false))
                handleMessage('Camera added!')
            }}>
                <FaRegWindowClose onClick={() => dispatch(setAddCameraActive(false))}/>
                <label htmlFor='film'>Camera Name:</label>
                <input 
                    type='text'
                    id='film'
                    value={cameraName} 
                    name='film' 
                    placeholder='Camera Name'
                    onChange={(e) => setCameraName(e.target.value)}
                />

                <TagsInput
                    value={lenses}
                    onChange={setLenses}
                    name="lenses"
                    placeHolder="Enter lenses for this camera"
                />
                <em>press enter to add new lens</em>

                <TagsInput
                    value={shootingModes}
                    onChange={setShootingModes}
                    name="shooting modes"
                    placeHolder="Enter shooting modes for this camera"
                />
                <em>press enter to add new shooting mode</em>

                <input 
                    type='submit'
                    value='Add'
                    className='submit-add-camera-button'
                />        
            </form>
        </div>
    )
}

export default AddCamera