import React, { useEffect, useRef, useState } from 'react'
import './SelectPhoto.css'

import { TfiLayoutGrid3Alt } from 'react-icons/tfi'
import { RiLayoutBottom2Line } from 'react-icons/ri'
import { HiBars4 } from 'react-icons/hi2'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

import Photo from './Photo/Photo'
import EditPhoto from './EditPhoto/EditPhoto'

import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentCameraId, setCurrentCameraId } from '../../reducers/cameraSlice'
import { selectCurrentRollId, setCurrentRollId } from '../../reducers/rollSlice'
import { selectData } from '../../reducers/AppSlice'
import { formatRollName } from '../../helpers'
import { 
  selectToggleEditPhoto, 
  selectTogglePhotoModal, 
  setToggleEditPhoto, 
  setTogglePhotoModal, 
  setUploadProgress, 
} from '../../reducers/photoSlice'
import ImageGallery from 'react-image-gallery'
import { FaWindowClose } from 'react-icons/fa'
import { useSwipeable } from 'react-swipeable'


const SelectPhoto = ({ 
  handleChangeRollAndNavigate, 
  setToggleAdd,
  handleMessage,
  handleUploadImage,
}) => {

  const data = useSelector(selectData)
  const currentCameraId = useSelector(selectCurrentCameraId)
  const currentRollId = useSelector(selectCurrentRollId)
  const togglePhotoModal = useSelector(selectTogglePhotoModal)
  const toggleEditPhoto = useSelector(selectToggleEditPhoto)

  const [localCameraData, setLocalCameraData] = useState({})
  const [localRollData, setLocalRollData] = useState({})
  const [toggleLayoutGrid, setToggleLayoutGrid] = useState('list')
  const [toggleShowHidden, setToggleShowHidden] = useState(false)
  const [defaultCameraValue, setDefaultCameraValue] = useState('')
  const [defaultRollValue, setDefaultRollValue] = useState('')
  const [imageGalleryStartIndex, setImageGalleryStartIndex] = useState(0)
  const [startY, setStartY] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  
  const modalRef = useRef(null);
  
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()

  useEffect(() => {

    const cameraId = Number(searchParams.get('camera_id'))
    const rollId = Number(searchParams.get('roll_id'))

    if (data && cameraId && rollId ) {
      data.forEach(camera => {
        if (camera.id === cameraId) {
          setLocalCameraData(camera)
          camera.film_rolls.forEach(roll => {
            if (roll.id === rollId) {
              setLocalRollData(roll)
            }
          })
        }
      })
    }
  },[data, currentRollId, searchParams])

  useEffect(() => {
    const getDefaultCameraValue = () => {
      if (data) {
        data.forEach(camera => {
          if (camera.id === currentCameraId) {
            setDefaultCameraValue(JSON.stringify(camera))
            camera.film_rolls.forEach(roll => {
              if (roll.id === currentRollId) {
                setDefaultRollValue(JSON.stringify(roll))
              }
            })
          } 
        })
      }
    }
    getDefaultCameraValue()
  },[data, setDefaultCameraValue, currentCameraId, currentRollId, setDefaultRollValue])

  const handlers = useSwipeable({
    onSwipedDown: () => {
      dispatch(setTogglePhotoModal(false))
    },
    onSwipedUp: () => {
      dispatch(setTogglePhotoModal(false))
    },
    preventScrollOnSwipe: true,

  });

  

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const deltaY = e.touches[0].clientY - startY;
    modalRef.current.style.transform = `translate(0%, calc(-50% + ${deltaY}px))`;
  };

  const handleTouchEnd = () => {
    if (isDragging) {
      setIsDragging(false);
      modalRef.current.style.transform = 'translate(0%, -50%)';
      const deltaY = window.innerHeight / 2 - modalRef.current.getBoundingClientRect().top;
      if (deltaY < 320) {
        dispatch(setTogglePhotoModal(false))
      }
    }
  };
  
  return (
    <div className='select-photo-master-container'>
      <select 
        onChange={(e) => {
            const temp = JSON.parse(e.target.value)
            dispatch(setCurrentCameraId(temp.id))
            if (temp.film_rolls.length > 0) {
              dispatch(setCurrentRollId(temp.film_rolls[0].id))
            }
            handleChangeRollAndNavigate('/select-photo', temp.id, temp.film_rolls[0].id)
          }
        }
        value={defaultCameraValue}
      >
        {data ? (
          data.map(camera => {
            return (
              <option 
                key={camera.id} 
                value={JSON.stringify(camera)} 
                // selected={camera.id === currentCameraId ? 'selected' : ''}
              >
                {formatRollName ? formatRollName(camera, 'user') :''}
              </option>
            )
          })
        ) : ''}
      </select>

      <select 
        onChange={(e) => {
          const temp = JSON.parse(e.target.value)
          dispatch(setCurrentRollId(temp.id))
          handleChangeRollAndNavigate('/select-photo', localCameraData.id, temp.id)
          }
        }
        value={defaultRollValue}
      >
        {localCameraData.film_rolls ? (
          localCameraData.film_rolls.map(roll => {
            return (
              <option 
                key={roll.id} 
                value={JSON.stringify(roll)}
              >
                {formatRollName ? formatRollName(roll, 'user') : ''}
              </option>
            )
          })
        ) : ''}
        
      </select>

      <h2>{localRollData.name && formatRollName ? formatRollName(localRollData, 'user') : ''}</h2>

      <div className='mid-nav'>
        <HiBars4 
          size='20' 
          onClick={() => {
              setToggleAdd(false)  
              setToggleLayoutGrid('list')
            }
          }      
        />
        <TfiLayoutGrid3Alt 
          size='20' 
          onClick={() => {
              setToggleAdd(false)  
              setToggleLayoutGrid('grid')
            }
          }
        />
        <RiLayoutBottom2Line 
          size='20' 
          onClick={() => {
              setToggleAdd(false)  
              setToggleLayoutGrid('scroll')
            }
          }
        />
        {toggleShowHidden ? <AiOutlineEye size='20' onClick={() => setToggleShowHidden(!toggleShowHidden)}/> : <AiOutlineEyeInvisible size='20' onClick={() => setToggleShowHidden(!toggleShowHidden)}/> }
          
      </div>


      <div 
        className={`photo-container ${toggleLayoutGrid}`}
      >
        <div>
          <div 
            className={`photo-modal-container ${togglePhotoModal ? 'active' : ''}`}
            onClick={() => {
              dispatch(setTogglePhotoModal(false))
              dispatch(setUploadProgress(0))
            }}
          >
          </div>
          <div 
            className={`photo-modal ${togglePhotoModal ? 'active' : ''}`}
            {...handlers}
            draggable="true"
            ref={modalRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <FaWindowClose 
              onClick={() => dispatch(setTogglePhotoModal(false))}
              style={{
                visibility: `${togglePhotoModal ? 'visible' : 'hidden'}`
              }}
            />
            <ImageGallery 
              items={localRollData.photos ? (
                localRollData.photos.map(photo => {
                  return {
                    ...photo, 
                    original: photo.url, 
                    originalWidth: '100%',
                    originalTitle: photo.subject,
                    originalClass: 'image-gallery-photo',
                    description: `${photo.subject} | ${photo.lens} | F${photo.f_stop} | ${photo.shutter_speed} | ${photo.exposure_comp}X | ${photo.shooting_mode}`,
                    // <h4>{index}</h4>
                    // <h4>{photo.lens}</h4>
                    // <h4>F{photo.f_stop}</h4>
                    // <h4>{photo.shutter_speed}s</h4>
                    // <h4>{photo.exposure_comp} EF</h4>
                    // <h4>{photo.shooting_mode}</h4>
                  }
                })
              ) : []}
              showNav={false}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              startIndex={imageGalleryStartIndex}
              infinite={false}
            />
            
          </div>
          {/* <Carousel
            width='100vw'
            showThumbs='false'
            showArrows='false'
            showIndicators='false'
            // axis='vertical'
            dynamicHeight='true'
            // centerMode='true'
          >
            {localRollData.photos ? (
              localRollData.photos.map(photo => {
                return (
                  <div key={photo.id}>
                    <img src={photo.url} alt={photo.subject}/>
                  </div>
                )
              })
            ) : ''}
          </Carousel> */}
          {/* <PhotoModal 
            handleUploadImage={handleUploadImage}
          /> */}

        </div>
        <div>
          <div
            className={`edit-photo-container ${toggleEditPhoto ? 'active' : ''}`}
            onClick={() => {
              dispatch(setToggleEditPhoto(false))
            }}
          >
          </div>
          <EditPhoto
              localData={localRollData}
              handleMessage={handleMessage}
              handleUploadImage={handleUploadImage}
          />
        </div>
        {localRollData.photos ? (
          localRollData.photos.map(photo => {
            if (!toggleShowHidden 
                  && (photo.subject.toLowerCase() === 'unknown' 
                        || photo.subject.toLowerCase().includes('failed')
                      )
                ) {
              return ''
            } else {
              return (
                <Photo 
                  index={localRollData.photos.indexOf(photo)}
                  key={photo.id}
                  photo={photo}
                  toggleLayoutGrid={toggleLayoutGrid} 
                  setToggleLayoutGrid={setToggleLayoutGrid}
                  setImageGalleryStartIndex={setImageGalleryStartIndex}
                />
              )
            }
          })
        ) : ''}
      </div>
      
    </div>
  )
}

export default SelectPhoto