import React from 'react'
import './Photo.css'
import { selectTogglePhotoModal, setCurrentPhotoId, setTogglePhotoModal } from '../../../reducers/photoSlice'
import { useDispatch, useSelector } from 'react-redux'

const Photo = ({ 
    index, 
    photo, 
    toggleLayoutGrid, 
    setToggleLayoutGrid,
    setImageGalleryStartIndex,
}) => {

    const togglePhotoModal = useSelector(selectTogglePhotoModal)
    const dispatch = useDispatch()

    if (photo) {
        if (toggleLayoutGrid) {
            if (toggleLayoutGrid === 'grid' || toggleLayoutGrid === 'scroll') {
                return (
                    <div 
                        className={`photo-settings grid`}
                        onClick={() => {
                            dispatch(setCurrentPhotoId(photo.id))
                            setImageGalleryStartIndex(index)
                            if (toggleLayoutGrid === 'grid') {
                                dispatch(setTogglePhotoModal(!togglePhotoModal))
                                // setToggleLayoutGrid('scroll')
                            }
                        }}
                        style={
                            toggleLayoutGrid === 'scroll' ? ({
                                width: '100%',
                                aspectRatio: 'auto',
                            }) : ({
                                width: '33.33%',
                                aspectRatio: '1/1',
                                border: '0',
                            })
                        }
                    >
                        <img 
                            src={photo.url}
                            alt={photo.subject}
                            style={{
                            }}
                        />
                        {toggleLayoutGrid === 'scroll'? (
                            <div className='scroll-photo-settings'>
                                <div>
                                    <h3 className='photo-subject-title'><strong>{photo.subject}</strong></h3>
                                    <p>{photo.date_created}</p>

                                </div>
                                <div>
                                    <h4>{index}</h4>
                                    <h4>{photo.lens}</h4>
                                    <h4>F{photo.f_stop}</h4>
                                    <h4>{photo.shutter_speed}s</h4>
                                    <h4>{photo.exposure_comp} EF</h4>
                                    <h4>{photo.shooting_mode}</h4>
                                </div>
                            </div>
                        ) : ''}
                        
                    </div>
                )
            } else if (toggleLayoutGrid === 'list') {
                return (
                    <div 
                        className={`photo-settings list`}
                        onClick={() => {
                            dispatch(setTogglePhotoModal(!togglePhotoModal))
                            dispatch(setCurrentPhotoId(photo.id))
                            setImageGalleryStartIndex(index)
                        }}
                    >
                        <div>
                            <h3 className='photo-subject-title'><strong>{photo.subject}</strong></h3>
                            <p>{photo.date_created}</p>

                        </div>
                        <div>
                            <h4>{index}</h4>
                            <h4>{photo.lens}</h4>
                            <h4>F{photo.f_stop}</h4>
                            <h4>{photo.shutter_speed}s</h4>
                            <h4>{photo.exposure_comp} EF</h4>
                            <h4>{photo.shooting_mode}</h4>
                        </div>
                    </div>
    
                )
            }
        }
    }
}

export default Photo