import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentCameraId: 1,
    addCameraActive: false,
    addLensActive: false,
}

const cameraSlice = createSlice({
    name: 'camera',
    initialState,
    reducers: {
        setCurrentCameraId: (state, action) => {
            state.currentCameraId = action.payload
        },
        setAddCameraActive: (state, action) => {
            state.addCameraActive = action.payload
        },
        setAddLensActive: (state, action) => {
            state.addLensActive = action.payload
        },
    }
})

export const {
    setCurrentCameraId,
    setAddCameraActive,
    setAddLensActive,
} = cameraSlice.actions

export const selectCurrentCameraId = (state) => state.camera.currentCameraId
export const selectAddCameraActive = (state) => state.camera.addCameraActive
export const selectAddLensActive = (state) => state.camera.addLensActive

export default cameraSlice.reducer