import React, {useState} from 'react';
import './Login.css'

import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { selectToggleLoginError } from '../../reducers/userSlice';
 
const Login = ({
  handleLoginUser,
}) => {

  const toggleLoginError = useSelector(selectToggleLoginError)


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
       
    return(
      
      <div className='login'>   
        <h2>Login:</h2>
        <form>                                              
          {/* <label htmlFor="email-address">Email address</label> */}
          <input
              id="email-address"
              name="email"
              type="email"                                    
              required                                                                                
              placeholder="Email address"
              onChange={(e)=>setEmail(e.target.value)}
          />

          {/* <label htmlFor="password">Password</label> */}
          <input
              id="password"
              name="password"
              type="password"                                    
              required                                                                                
              placeholder="Password"
              onChange={(e)=>setPassword(e.target.value)}
          />
          {toggleLoginError ? <p className='error-message'>Login error: <br/> Incorrect email/password</p>: ''}
          <button className='login-button' onClick={(e) => handleLoginUser(e, email, password)}>Login</button>
        </form>
        
        <p className="text-sm text-white text-center">
            No account? {' '}
            <br/>
            <NavLink to="/sign-up">
                Sign up
            </NavLink>
        </p>                   
      </div>
    )
}
 
export default Login