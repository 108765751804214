import React from 'react'
import './Header.css'

import { MdCameraRoll } from 'react-icons/md'

const Header = ({ 
  navigate,
  setActiveIcon,
}) => {
  return ( 
    <header onClick={() => {
      navigate(`/`)
      setActiveIcon('cameraRoll')
    }}>
      <h1><MdCameraRoll size='37'/> <b>Film</b> Catalog</h1>
    </header>
  )
}

export default Header