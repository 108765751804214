import React, { useEffect, useState } from 'react'
import './AddRoll.css'

import { FaRegWindowClose } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentCameraId, setCurrentCameraId } from '../../reducers/cameraSlice'
import { setAddRollActive, setCurrentRollId } from '../../reducers/rollSlice'
import { addRolltoCamera, selectData } from '../../reducers/AppSlice'
import { selectUserId } from '../../reducers/userSlice'
import { createDateString, formatRollName, generateId } from '../../helpers'

const AddRoll = ({ 
    handleMessage,
 }) => {

    const data = useSelector(selectData)
    const currentCameraId = useSelector(selectCurrentCameraId)
    const userId = useSelector(selectUserId)

    const dispatch = useDispatch()

    const [filmType, setFilmType] = useState('')
    const [exposures, setExposures] = useState('')
    const [defaultCameraValue, setDefaultCameraValue] = useState('')

    useEffect(() => {
        const getDefaultCameraValue = () => {
        if (data) {
            data.forEach(camera => {
            if (camera.id === currentCameraId) {
                setDefaultCameraValue(JSON.stringify(camera))
                
            } 
            })
        }
        }
        getDefaultCameraValue()
    },[data, setDefaultCameraValue, currentCameraId])

  return (
    <div className='add-roll'>
        <form onSubmit={(e) => {
            e.preventDefault()
            handleMessage('Roll added!')
            dispatch(addRolltoCamera({
            userId: userId,
            cameraId: currentCameraId,
            rollData: {
                name: filmType.toLowerCase().replace(/ /g,"_"),
                exposures: exposures,
                id: generateId(),
                date_created: createDateString(),
                photos: []
            }
            }))
            dispatch(setAddRollActive(false))
        }}>
            <FaRegWindowClose onClick={() => dispatch(setAddRollActive(false))}/>
            <label htmlFor='camera'>Select Camera:</label>
            <select 
                onChange={(e) => {
                        const temp = JSON.parse(e.target.value)
                        dispatch(setCurrentCameraId(temp.id))
                        if (temp.film_rolls.length > 0) {
                            dispatch(setCurrentRollId(temp.film_rolls[0].id))
                        }
                    }
                }
                name='camera'
                id='camera'
                value={defaultCameraValue}
            >
                {data ? (
                data.map(camera => {
                    return (
                    <option 
                        key={camera.id} 
                        value={JSON.stringify(camera)} 
                    >
                        {formatRollName ? formatRollName(camera, 'user') :''}
                    </option>
                    )
                })
                ) : ''}
            </select>
            <label htmlFor='film'>Film Type:</label>
            <input 
                type='text'
                value={filmType} 
                name='film' 
                id='film' 
                className='film-type-input'
                onChange={(e) => setFilmType(e.target.value)}
                />

            <label htmlFor='exposures'>Exposures:</label>
            <input 
                type='text'
                value={exposures}
                name='exposures'
                id='exposures'
                className='exposures-input'
                onChange={(e) => setExposures(e.target.value)}
            />

            <input 
                type='submit'
                value='Add'
                className='submit-add-roll'
            />        
        </form>
    </div>
  )
}

export default AddRoll