import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentRollId: 1,
    addRollActive: false,
    toggleEditRoll: false,
    toggleDeleteRoll: false,
}

const rollSlice = createSlice({
    name: 'roll',
    initialState,
    reducers: {
        setCurrentRollId: (state, action) => {
            state.currentRollId = action.payload
        },
        setAddRollActive: (state, action) => {
            state.addRollActive = action.payload
        },
        setToggleEditRoll: (state, action) => {
            state.toggleEditRoll = action.payload
        },
        setToggleDeleteRoll: (state, action) => {
            state.toggleDeleteRoll = action.payload
        }
    }
})

export const { 
    setCurrentRollId,
    setAddRollActive,
    setToggleEditRoll,
    setToggleDeleteRoll
} = rollSlice.actions

export const selectCurrentRollId = (state) => state.roll.currentRollId
export const selectAddRollActive = (state) => state.roll.addRollActive
export const selectToggleEditRoll = (state) => state.roll.toggleEditRoll
export const selectToggleDeleteRoll = (state) => state.roll.toggleDeleteRoll

export default rollSlice.reducer