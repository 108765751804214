// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, doc } from 'firebase/firestore'
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkXJxwfAlqs0KhIFLszHibBiMrcYs-TJQ",
  authDomain: "film-catalog-b8470.firebaseapp.com",
  projectId: "film-catalog-b8470",
  storageBucket: "film-catalog-b8470.appspot.com",
  messagingSenderId: "337504673706",
  appId: "1:337504673706:web:88ec17683b9c58e63c15d3",
  measurementId: "G-R77NYBSK4F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)

export const getDocRef = (userId) => {
  const docRef = doc(db, 'users', userId)
  return docRef
}

export { db, auth, storage }