// ==============GENERATE ID HELPER FUNCTION==================== //
export const generateId = () => {
    return Math.floor(Math.random() * 100000)
}

// ==============CREATE DATE STRING HELPER FUNCTION==================== //
export const createDateString = () => {
    const dateObject = new Date()
    const dateCreated = dateObject.toLocaleString('en-US', {
        year: "numeric",
        month: "long",
        day: "numeric",
    }).replace("/", "-").replace("/", "-")

    return dateCreated
}

// ==============FORMAT ROLL NAME==================== //
export const formatRollName = (roll, destination) => {
    if (roll.name) {
      if (destination === 'db') {
        let name = roll.name.toLowerCase().replace(/ /g,"_")

          return name
        } else if (destination === 'user') {
          const arr = roll.name.split("_")
          let name = ''
          arr.forEach(word => {
            if (word.length <=2) {
              const s = word.toUpperCase() + " "
              name += s
            } else {
              const s = word.charAt(0).toUpperCase() + word.slice(1) + "_"
              name += s
            }
          })
          name = name.replace(/_/g," ").trim()
          return name
        }
    } else {
      console.log('Invalid input. roll must have roll.name value. And formatRollName must be called with destination either \'user\' or \'db\'')
    }
}