import React, { useEffect, useState } from 'react'
import './AddPhoto.css'

import { FaRegWindowClose } from 'react-icons/fa'

import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentCameraId, setCurrentCameraId } from '../../reducers/cameraSlice'
import { setAddPhotoActive } from '../../reducers/photoSlice'
import { selectCurrentRollId, setCurrentRollId } from '../../reducers/rollSlice'
import { addPhotoToRoll, selectData } from '../../reducers/AppSlice'
import { selectUserId } from '../../reducers/userSlice'
import { createDateString, formatRollName, generateId } from '../../helpers'


const AddPhoto = ({ 
    handleMessage, 
}) => {

    const data = useSelector(selectData)
    const userId = useSelector(selectUserId)
    const currentCameraId = useSelector(selectCurrentCameraId)
    const currentRollId = useSelector(selectCurrentRollId)

    const [subject, setSubject] = useState('')
    const [flash, setFlash] = useState(false)
    const [lastSettings, setLastSettings] = useState(false)
    const [lens, setLens] = useState('')
    const [fStop, setFStop] = useState(2)
    const [shutterSpeed, setShutterSpeed] = useState('1/2000')
    const [exposureComp, setExposureComp] = useState(1)
    const [shootingMode, setShootingMode] = useState('')

    const [localCameraData, setLocalCameraData] = useState({})
    const [defaultCameraValue, setDefaultCameraValue] = useState('')
    const [defaultRollValue, setDefaultRollValue] = useState('')
    

    const [searchParams] = useSearchParams()
    const dispatch = useDispatch()

    useEffect(() => {

        const cameraId = Number(searchParams.get('camera_id'))
        const rollId = Number(searchParams.get('roll_id'))

        if (data && cameraId && rollId ) {
        data.forEach(camera => {
            if (camera.id === currentCameraId) {
            setLocalCameraData(camera)

            }
        })
        }
    },[data, searchParams, currentCameraId])

    useEffect(() => {
        const getDefaultCameraValue = () => {
        if (data) {
            data.forEach(camera => {
            if (camera.id === currentCameraId) {
                setDefaultCameraValue(JSON.stringify(camera))
                camera.film_rolls.forEach(roll => {
                if (roll.id === currentRollId) {
                    setDefaultRollValue(JSON.stringify(roll))
                }
                })
            } 
            })
        }
        }
        getDefaultCameraValue()
    },[data, setDefaultCameraValue, currentCameraId, currentRollId, setDefaultRollValue])
    

  return (
    <div className='add-photo'>
        <form onSubmit={(e) => {
            e.preventDefault()
            if (subject !== '') {
                let id = generateId()
                //check if subject is unknown or includes failed
                if (subject.toLowerCase() === 'unknown' || subject.toLowerCase().includes('failed')) {
                    handleMessage('Photo added!')
                    dispatch(addPhotoToRoll({
                        userId: userId,
                        cameraId: currentCameraId,
                        rollId: currentRollId,
                        photoData: {
                            id: id,
                            subject: subject,
                            shooting_mode: shootingMode,
                            lens: lens,
                            shutter_speed: shutterSpeed,
                            f_stop: fStop,
                            exposure_comp: exposureComp,
                            date_created: createDateString(),
                            url: `https://firebasestorage.googleapis.com/v0/b/film-catalog-b8470.appspot.com/o/default%2Ffailed.JPEG?alt=media&token=56fc1c14-39dc-49ba-88cf-8f5b9dc55fc1`
                        }
                      }))
                    
                    dispatch(setAddPhotoActive(false))
                } else {
                    handleMessage('Photo added!')
                    dispatch(addPhotoToRoll({
                        userId: userId,
                        cameraId: currentCameraId,
                        rollId: currentRollId,
                        photoData: {
                            id: id,
                            subject: subject,
                            shooting_mode: shootingMode,
                            lens: lens,
                            shutter_speed: shutterSpeed,
                            f_stop: fStop,
                            exposure_comp: exposureComp,
                            date_created: createDateString(),
                            url: `https://firebasestorage.googleapis.com/v0/b/film-catalog-b8470.appspot.com/o/default%2Fdefault.JPEG?alt=media&token=7de6b132-7b5f-455c-aa63-1bc320d6df48`
                        }
                    }))
                    dispatch(setAddPhotoActive(false))
                }
            } 
        }}>
            <FaRegWindowClose onClick={() => dispatch(setAddPhotoActive(false))}/>
            <select 
                onChange={(e) => {
                    const temp = JSON.parse(e.target.value)
                    dispatch(setCurrentCameraId(temp.id))
                    if (temp.film_rolls.length > 0) {
                        dispatch(setCurrentRollId(temp.film_rolls[0].id))
                    }
                }
                }
                value={defaultCameraValue}
            >
                {data ? (
                    data.map(camera => {
                        return (
                        <option 
                            key={camera.id} 
                            value={JSON.stringify(camera)} 
                            // selected={camera.id === currentCameraId ? 'selected' : ''}
                        >
                            {formatRollName ? formatRollName(camera, 'user') :''}
                        </option>
                        )
                    })
                ) : ''}
            </select>

            <select 
                onChange={(e) => {
                        const temp = JSON.parse(e.target.value)
                        dispatch(setCurrentRollId(temp.id))
                    }
                }
                value={defaultRollValue}
            >
                {localCameraData.film_rolls ? (
                    localCameraData.film_rolls.map(roll => {
                        return (
                        <option 
                            key={roll.id} 
                            value={JSON.stringify(roll)}
                        >
                            {formatRollName ? formatRollName(roll, 'user') : ''}
                        </option>
                        )
                    })
                ) : ''}
            </select>

            <label htmlFor='subject'>Subject:</label>
            <input 
                type='text'
                value={subject} 
                name='subject' 
                id='subject' 
                className='subject-input'
                onChange={(e) => setSubject(e.target.value)}
                required
            />

            <div className='flash-container'>
                <input 
                    type='checkbox'
                    value={flash}
                    name='flash'
                    id='flash'
                    className='flash-input'
                    onChange={() => {
                        setFlash(!flash)
                        if (!subject.includes(' w/ flash')) {
                            const s = subject + ' w/ flash'
                            setSubject(s)
                        } else {
                            const s = subject.replace(' w/ flash', '')
                            setSubject(s)
                        }
                    }}
                />
                <label htmlFor='flash'>Flash?</label>
            </div>
            

            <div className='fieldset-container'>
                <fieldset 
                    onChange={(e) => {
                        let word = e.target.value
                        const s = word.charAt(0).toUpperCase() + word.slice(1)
                        setShootingMode(s)
                    }}
                    name='shooting-mode'
                >
                    <legend>Shooting Mode:</legend>
                    <div className='select-shooting-mode'>
                        {localCameraData.shooting_modes ? (
                            localCameraData.shooting_modes.map(modeItem => {
                                return (
                                    <span key={modeItem}>
                                        <input 
                                            type="radio" 
                                            name="mode" 
                                            value={shootingMode} 
                                            id={modeItem} 
                                            required
                                        />
                                        <label htmlFor={modeItem}>{modeItem}</label>
                                    </span>
                                )
                            })
                        ) : ''}
                    </div>

                </fieldset>

                <fieldset 
                    onChange={(e) => {
                        let word = e.target.value
                        const s = word.charAt(0).toUpperCase() + word.slice(1)
                        setLens(s)
                    }}
                    name='lens'
                >
                    <legend>Lens:</legend>
                    <div className='select-lens'>
                        {localCameraData.lenses ? (
                            localCameraData.lenses.map(lensItem => {
                                return (
                                    <span key={lensItem}>
                                        <input 
                                            type="radio" 
                                            name="lens" 
                                            value={lensItem} 
                                            id={lensItem} 
                                            required
                                        />
                                        <label htmlFor={lensItem}>{lensItem}</label>
                                    </span>
                                )
                            })

                        ) : ''}
                    </div>

                </fieldset>
            </div>
            {localCameraData && localCameraData.film_rolls ? (
                localCameraData.film_rolls.map(roll => {
                    if (roll.id === currentRollId) {
                        if (roll.photos.length > 0) {
                            return (
                                <div className='last-settings-container' key={roll.id}>
                                    <input 
                                        type='checkbox'
                                        value={lastSettings}
                                        name='last-settings'
                                        id='last-settings'
                                        className='last-settings-input'
                                        onChange={() => {
                                            setLastSettings(!lastSettings)
                                            if (lastSettings === false) {
                                                // const photoData = JSON.parse(localStorage.getItem('last_photo_settings'))
                                                
                                                let photoData = null; // Default value if not found
                                                for (const roll of localCameraData.film_rolls) {
                                                    if (roll.id === currentRollId) {
                                                        const index = roll.photos.length - 1;
                                                        photoData = roll.photos[index];
                                                        break; // Exit the loop once a match is found
                                                    }
                                                }
                                                if (photoData !== undefined) {
                                                    if (photoData.subject.includes('w/ flash')) {
                                                        setFlash(true)
                                                    }
                                                    setShootingMode(photoData.shooting_mode)
                                                    setLens(photoData.lens)
                                                    setShutterSpeed(photoData.shutter_speed)
                                                    setFStop(photoData.f_stop)
                                                    setExposureComp(photoData.exposure_comp)
                                                }
                                            } else {
                                                setFlash(false)
                                                setShootingMode('Auto')
                                                setLens('50mm')
                                                setShutterSpeed('1/2000')
                                                setFStop(2)
                                                setExposureComp(1)
                                            }
                                        }}
                                    />
                                    <label htmlFor='last-settings'>Use Last Photo Settings</label>
                                </div>
                            )
                        } else {
                            return ''
                        }
                    } else {
                        return ''
                    }
                })
            ) : ''}

            <label htmlFor='shutter-speed'>Shutter Speed:</label>
            <select name='shutter-speed' id='shutter-speed' onChange={(e) => setShutterSpeed(e.target.value)} value={shutterSpeed}>
                <option value='1/2000' >1/2000</option>
                <option value='1/1000'>1/1000</option>
                <option value='1/500' >1/500</option>
                <option value='1/250' >1/250</option>
                <option value='1/125' >1/125</option>
                <option value='1/60' >1/60</option>
                <option value='1/30' >1/30</option>
                <option value='1/15' >1/15</option>
                <option value='1/8' >1/8</option>
                <option value='1/4' >1/4</option>
                <option value='1/2' >1/2</option>
                <option value='1/1' >1/1</option>
                <option value='2' >2</option>
                <option value='4' >4</option>
            </select>

            <label htmlFor='f-stop'>F-Stop: {fStop}</label>
            <input 
                type='range'
                value={fStop} 
                name='f-stop' 
                id='f-stop' 
                list='f-stop-markers'
                min='1'
                max='22'
                step='0.1'
                onChange={(e) => setFStop(e.target.value)}
                required
            />
            <datalist id='f-stop-markers'>
                <option value='1.2' ></option>
                <option value='2' ></option>
                <option value='2.8'></option>
                <option value='4' ></option>
                <option value='5.6' ></option>
                <option value='8' ></option>
                <option value='11' ></option>
                <option value='16' ></option>
                <option value='22' ></option>
            </datalist>

            <label htmlFor='exposure-comp'>Exposure Comp: {exposureComp}</label>     
            <input 
                type='range'
                value={exposureComp}
                name='exposure-comp'
                id='exposure-comp'
                list='exposure-comp-markers'
                min='-4'
                max='4'
                step='0.25'
                onChange={(e) => setExposureComp(e.target.value)}
                required
            />
            <datalist id='exposure-comp-markers'>
                <option value='-4'></option>
                <option value='-2'></option>
                <option value='1'></option>
                <option value='2'></option>
                <option value='4'></option>
            </datalist>

            

            <input 
                type='submit'
                value='Add'
                className='submit-add-photo'
            />     
        </form>
    </div>
  )
}

export default AddPhoto