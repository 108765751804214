import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    message: '',
    toggleMessage: false,
}

const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        setMessage: (state, action) => {
            state.message = action.payload
        },
        setToggleMessage: (state, action) => {
            state.toggleMessage = action.payload
        },
    }
})

export const {
    setMessage,
    setToggleMessage,
} = messageSlice.actions

export const selectMessage = (state) => state.message.message
export const selectToggleMessage = (state) => state.message.toggleMessage

export default messageSlice.reducer
