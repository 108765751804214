import React, { useEffect } from 'react'
import './Home.css'
import { useSelector } from 'react-redux'
import { selectUserId } from '../../reducers/userSlice'
import { selectCurrentCameraId } from '../../reducers/cameraSlice'
import { selectCurrentRollId } from '../../reducers/rollSlice'

const Home = ({
    handleChangeRollAndNavigate,
}) => {

    
    
    const userId = useSelector(selectUserId)
    const currentCameraId = useSelector(selectCurrentCameraId)
    const currentRollId = useSelector(selectCurrentRollId)
    
    useEffect(() => {
        if (userId) {
            handleChangeRollAndNavigate('/select-roll', currentCameraId, currentRollId)
        }
    },[handleChangeRollAndNavigate, currentCameraId, currentRollId, userId])

  return (
    <div className='home'>
        <h1>Welcome!</h1>
        <button onClick={() => {
            handleChangeRollAndNavigate('/login')
        }}>Login</button>
        <button onClick={() => {
            handleChangeRollAndNavigate('/sign-up')
        }}>Sign Up</button>
    </div>
  )
}

export default Home