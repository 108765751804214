import React, { useState } from 'react'
import './RollListItem.css'

import { 
    // FaRegWindowClose, 
    // FaEdit, 
    FaEllipsisH 
} from 'react-icons/fa'
// import { FaTrashCan } from 'react-icons/fa6'

import { useDispatch, useSelector } from 'react-redux'
import { selectUserId } from '../../../../reducers/userSlice'
import { selectCurrentRollId, selectToggleDeleteRoll, selectToggleEditRoll, setCurrentRollId, setToggleDeleteRoll, setToggleEditRoll } from '../../../../reducers/rollSlice'
import { selectCurrentCameraId, setCurrentCameraId } from '../../../../reducers/cameraSlice'
import { deleteRollFromCamera } from '../../../../reducers/AppSlice'
import { formatRollName } from '../../../../helpers'

const RollListItem = ({
    camera,
    roll,
    localCurrentRollId,
    setLocalCurrentRollId,
    handleChangeRollAndNavigate,
    setActiveIcon,
}) => {

    const userId = useSelector(selectUserId)
    const currentCameraId = useSelector(selectCurrentCameraId)
    const currentRollId = useSelector(selectCurrentRollId)
    const toggleEditRoll = useSelector(selectToggleEditRoll)
    const toggleDeleteRoll = useSelector(selectToggleDeleteRoll)

    const [toggleRollSubMenu, setToggleRollSubMenu] = useState(false)

    const dispatch = useDispatch()

  return (
    <li 
        key={roll.id} 
        className={`li ${roll.id === localCurrentRollId || roll.id === currentRollId ? 'active' : ''} ${roll.name === 'Loading...' ? 'loading' : ''}`}
    >
        <div className='roll-info'>
            {`${roll.photos.length} / ${roll.exposures}`}
            <div className='display-info' onClick={() => {
                setLocalCurrentRollId(Number(roll.id))
                handleChangeRollAndNavigate(`/select-photo`, Number(camera.id), Number(roll.id))
                setActiveIcon('selectPhoto')
            }}>
                {formatRollName(roll, 'user')}
                <h6>{roll.date_created}</h6>
            </div>
            <FaEllipsisH size ='20' onClick={() => {
                setToggleRollSubMenu(!toggleRollSubMenu)
            }}/>
            {toggleRollSubMenu ? (
                <div className='roll-sub-menu'>
                    {/* <FaRegWindowClose className='roll-sub-menu-close-button'onClick={() => setToggleRollSubMenu(false)}/> */}
                    <button 
                        className='roll-list-item-button'
                        onClick={() => {
                            setToggleRollSubMenu(false)
                            dispatch(setToggleEditRoll(!toggleEditRoll))
                            dispatch(setCurrentRollId(roll.id))
                            dispatch(setCurrentCameraId(camera.id))
                        }}>
                        {/* <FaEdit size='20' /> */}
                        Edit Roll
                    </button>
                    <button 
                        className='roll-list-item-button'
                        onClick={() => {
                            dispatch(setToggleDeleteRoll(true))
                            dispatch(setCurrentCameraId(camera.id))
                            dispatch(setCurrentRollId(roll.id))
                            
                        }}>
                        {/* <FaTrashCan size='20' /> */}
                        Delete Roll
                    </button>
                </div>
            ) : ''}
        </div>
        {toggleDeleteRoll ? (
        <div>
          <div 
            className={`delete-roll-container ${toggleDeleteRoll ? 'active': ''}`}
            onClick={() => {
              dispatch(setToggleDeleteRoll(false))
            }}
          ></div>
        <div className={`toggle-delete-roll-container ${toggleDeleteRoll ? 'active' : ''}`}>
            <p>Are you sure you want to delete this roll?</p>
            <span>
                <button
                    onClick={() => {
                        setToggleRollSubMenu(false)
                            dispatch(deleteRollFromCamera({
                                userId: userId,
                                cameraId: currentCameraId,
                                rollId: currentRollId,
                            }))
                        dispatch(setToggleDeleteRoll(false))
                    }}
                >Yes</button>
                <button
                    onClick={() => {
                        dispatch(setToggleDeleteRoll(false))
                    }}
                >No</button>
            </span>
        </div>
        </div>
      ) : ''}
    </li>
  )
}

export default RollListItem