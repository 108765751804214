import React, { useEffect, useState } from 'react'
import './EditRoll.css'

import { FaRegWindowClose } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentCameraId } from '../../../reducers/cameraSlice'
import { editRoll, selectData } from '../../../reducers/AppSlice'
import { selectUserId } from '../../../reducers/userSlice'
import { selectCurrentRollId, selectToggleEditRoll, setToggleEditRoll } from '../../../reducers/rollSlice'
import { formatRollName } from '../../../helpers'

const EditRoll = ({ 
    handleMessage,
}) => {

    const data = useSelector(selectData)
    const userId = useSelector(selectUserId)
    const currentCameraId = useSelector(selectCurrentCameraId)
    const currentRollId = useSelector(selectCurrentRollId)
    const toggleEditRoll = useSelector(selectToggleEditRoll)

    const [localRollData, setLocalRollData] = useState()
    const [filmName, setFilmName] = useState({name: ''})
    const [exposures, setExposures] = useState('')

    const dispatch = useDispatch()

    useEffect(() => {
        if (data) {
            data.forEach(camera => {
                if (camera.id === currentCameraId) {
                    camera.film_rolls.forEach(item => {
                        if (item.id === currentRollId) {
                            setLocalRollData(item)
                            setFilmName({name: formatRollName(item, 'user')})
                            setExposures(item.exposures)
                        }
                    })
                }
            })
        }
    },[data, currentRollId, currentCameraId])
    
  return (
    <div className={`edit-roll ${toggleEditRoll ? 'active' : ''}`}>
        <form 
            onSubmit={(e) => {
                e.preventDefault()
                const name = formatRollName(filmName, 'db')
                dispatch(editRoll({
                    userId: userId,
                    cameraId: currentCameraId,
                    rollData: {
                        name: name,
                        exposures: exposures,
                        id: currentRollId,
                        date_created: localRollData ? localRollData.date_created : null,
                        photos: localRollData ? localRollData.photos : []
                    }
                }))
                handleMessage('Roll Edited!')
                dispatch(setToggleEditRoll(false))
            }}
        >
            <FaRegWindowClose 
                onClick={() => dispatch(setToggleEditRoll(false))}
            />
            <label htmlFor='film'>Film Type: <br/> {localRollData ? formatRollName(localRollData, 'user') : ''}</label>
            <input 
                type='text'
                value={filmName.name} 
                name='film'
                id='film' 
                onChange={(e) => setFilmName({name: e.target.value})}
            />

            <label htmlFor='exposures'>Exposures:</label>
            <input 
                type='text'
                value={exposures}
                name='exposures'
                id='exposures'
                onChange={(e) => setExposures(e.target.value)}
            />

            <input 
                type='submit'
                value='Submit'
            />        
        </form>
    </div>
  )
}

export default EditRoll