import React, {useState} from 'react';
import './SignUp.css'

import { NavLink } from 'react-router-dom';

const SignUp = ({
    handleSignUpNewUser,
}) => {
 const [email, setEmail] = useState('')
 const [password, setPassword] = useState('')
 const [confirmPassword, setConfirmPassword] = useState('')
 
    const confirmPasswordStyle = {
        border: '1px solid red',
    }

 return (
    <div className='sign-up'>
        <h2>Sign Up:</h2>
        <form>
            <label htmlFor="email-address">Email address</label>
            <input
                type="email"
                label="Email address"
                name="email-address"
                id="email-address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email address"
            />

            <label htmlFor="password">Password</label>
            <input
                type="password"
                name="password"
                id="password"
                label="Create password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Password"
            />
            <label htmlFor="confirm-password">Confirm Password</label>
            {confirmPassword !== password ? 'passwords do not match' : ''}
            <input
                type="password"
                name='confirm-password'
                id='confirm-password'
                label="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                placeholder="Confirm Password"
                style={confirmPassword !== password ? confirmPasswordStyle : {}}
            />
            <button
                type="submit"
                onClick={(e) => handleSignUpNewUser(e, email, password)}
            >Sign up</button>
        </form>

        <p>Already have an account?{' '}
            <br/>
            <NavLink to="/login" >
                Sign in
            </NavLink>
        </p>
    </div>
 )
}
export default SignUp