import React, { useEffect, useRef, useState } from 'react'
import './EditPhoto.css'

import { FaRegWindowClose } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
     selectCurrentPhotoId, 
     selectToggleEditPhoto, 
     selectUploadProgress, 
     setToggleEditPhoto, 
     setUploadProgress,
} from '../../../reducers/photoSlice'
import { editPhoto, selectData } from '../../../reducers/AppSlice'
import { selectUserId } from '../../../reducers/userSlice'
import { selectCurrentCameraId } from '../../../reducers/cameraSlice'
import { selectCurrentRollId } from '../../../reducers/rollSlice'
import { useSwipeable } from 'react-swipeable'

const EditPhoto = ({ 
    localData,
    handleMessage,
    handleUploadImage,
}) => {

    const data = useSelector(selectData)
    const userId = useSelector(selectUserId)
    const currentCameraId = useSelector(selectCurrentCameraId)
    const currentRollId = useSelector(selectCurrentRollId)
    const currentPhotoId = useSelector(selectCurrentPhotoId)
    const toggleEditPhoto = useSelector(selectToggleEditPhoto)
    const uploadProgress = useSelector(selectUploadProgress)

    const [subject, setSubject] = useState('')
    const [flash, setFlash] = useState(false)
    const [lens, setLens] = useState('')
    const [fStop, setFStop] = useState(2)
    const [shutterSpeed, setShutterSpeed] = useState('1/2000')
    const [exposureComp, setExposureComp] = useState(1)
    const [shootingMode, setShootingMode] = useState('Manual')
    const [localCameraData, setLocalCameraData] = useState({})
    const [imageUpload, setImageUpload] = useState(null)

    const hiddenFileInput = useRef(null)

    const dispatch = useDispatch()

    const handlers = useSwipeable({
        preventScrollOnSwipe: true
    })

    useEffect(() => {
        if (localData && localData.photos) {
            localData.photos.forEach(photo => {
                if (photo.id === currentPhotoId) {
                    setSubject(photo.subject)
                    setLens(photo.lens)
                    setFStop(photo.f_stop)
                    setShutterSpeed(photo.shutter_speed)
                    setExposureComp(photo.exposure_comp)
                    setShootingMode(photo.shooting_mode)
                    if (photo.subject.includes(' w/ flash')) {
                        setFlash(true)
                    }
                }
            })
        }
    },[localData, currentPhotoId, setSubject, setFStop, setShutterSpeed, setExposureComp, setShootingMode])

    useEffect(() => {
        if (data) {
            data.forEach(camera => {
                if (camera.id === currentCameraId) {
                    setLocalCameraData(camera)
                }
            })
        }
    },[data, setLocalCameraData, currentCameraId])

    const handleClick = () => {
        hiddenFileInput.current.click()
    }

return (
    <div 
        className={`edit-photo ${toggleEditPhoto === true ? 'active' : ''} `}
        {...handlers}
    >
        <FaRegWindowClose  onClick={() => dispatch(setToggleEditPhoto(false))}/>
            {localData && localData.photos ? (
                localData.photos.map(photo => {
                    if (photo.id === currentPhotoId) {
                        return (
                            <form 
                                key={photo.id}
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    dispatch(setToggleEditPhoto(false))
                                    dispatch(editPhoto({
                                        userId: userId,
                                        cameraId: currentCameraId,
                                        rollId: currentRollId,
                                        photoData: {...photo, 
                                            'subject': subject,
                                            'lens': lens,
                                            'f_stop': fStop,
                                            'shutter_speed': shutterSpeed,
                                            'exposure_comp': exposureComp,
                                            'shooting_mode': shootingMode,
                                        }
                                    }))
                                  handleMessage('Photo Edited!')
                                }}
                            >
                                <img 
                                    src={photo.url}
                                    alt={photo.subject}
                                />
                                {uploadProgress === 100 ? (
                                    <p>Upload Complete!</p>
                                ) : (
                                    <div>
                                    <div 
                                        className='upload-progress'
                                        style={{
                                            width: `${uploadProgress}%`,
                                            height: '0.5rem',
                                            backgroundColor: 'green',
                                            position: 'relative',
                                            zIndex: '10',
                                        }}
                                    >
                                    </div>
                                    {imageUpload !== null ? (
                                        <p>File: {imageUpload.name}</p>
                                    ) : ''}
                                    </div>

                                )}
                                <div className='image-upload-container'>
                                    <input 
                                        type="file"
                                        onChange={(e) => {
                                            setImageUpload(e.target.files[0])
                                        }}
                                        ref={hiddenFileInput}
                                        style={{display: 'none'}}
                                    />
                                    <button 
                                        onClick={handleClick}
                                        type='button'
                                    >Select Upload</button>
                                    <button 
                                        onClick={() => {
                                            handleUploadImage(imageUpload)
                                            setImageUpload(null)
                                            dispatch(setUploadProgress(0))
                                        }}
                                        type='button'
                                    >Upload Image</button>
                                </div>
                                <label htmlFor='subject'>Subject:</label>
                                <input 
                                    type='text'
                                    value={subject} 
                                    name='subject' 
                                    id='subject' 
                                    onChange={(e) => setSubject(e.target.value)}
                                />

                                <div className='flash-container'>
                                    <input 
                                        type='checkbox'
                                        value={flash}
                                        name='flash'
                                        id='flash'
                                        className='flash-input'
                                        checked={flash}
                                        onChange={() => {
                                            setFlash(!flash)
                                            if (!subject.includes(' w/ flash')) {
                                                const s = subject + ' w/ flash'
                                                setSubject(s)
                                            } else {
                                                const s = subject.replace(' w/ flash', '')
                                                setSubject(s)
                                            }
                                        }}
                                    />
                                    <label htmlFor='flash'>Flash?</label>
                                </div>

                                <div className='fieldset-container'>
                                    <fieldset 
                                        onChange={(e) => {
                                            let word = e.target.value
                                            const s = word.charAt(0).toUpperCase() + word.slice(1)
                                            setShootingMode(s)
                                        }}
                                        name='shooting-mode'
                                    >
                                        <legend>Shooting Mode:</legend>
                                        <div className='select-shooting-mode'>
                                            {shootingMode}
                                            {localCameraData.shooting_modes.map(modeItem => {
                                                return (
                                                    <span key={modeItem}>
                                                        <input 
                                                            type="radio" 
                                                            name="mode" 
                                                            value={modeItem} 
                                                            id={modeItem} 
                                                            required
                                                            checked={modeItem === shootingMode ? true : false}
                                                            readOnly
                                                        />
                                                        <label htmlFor={modeItem}>{modeItem}</label>
                                                    </span>
                                                )
                                            })}
                                        </div>

                                    </fieldset>

                                    <fieldset 
                                        onChange={(e) => {
                                            let word = e.target.value
                                            const s = word.charAt(0).toUpperCase() + word.slice(1)
                                            setLens(s)
                                        }}
                                        name='lens'
                                    >
                                        <legend>Lens:</legend>
                                        <div className='select-lens'>
                                            {lens}
                                            {localCameraData.lenses.map(lensItem=> {
                                                return (
                                                    <span key={lensItem}>
                                                        <input 
                                                            type="radio" 
                                                            name="lens" 
                                                            value={lensItem} 
                                                            id={lensItem} 
                                                            required
                                                            checked={lensItem === lens ? true : false}  
                                                            readOnly  
                                                        />
                                                        <label htmlFor={lensItem}>{lensItem}</label>
                                                    </span>
                                                )
                                            })}
                                        </div>

                                    </fieldset>
                                </div>

                                {/* <label htmlFor='lens'>Lens:</label>
                                <input 
                                    type='text'
                                    value={lens} 
                                    name='lens' 
                                    onChange={(e) => setLens(e.target.value)}
                                    required
                                /> */}

                                <label htmlFor='shutter-speed'>Shutter Speed:</label>
                                <select 
                                    name='shutter-speed' 
                                    id='shutter-speed' 
                                    onChange={(e) => setShutterSpeed(e.target.value)} 
                                    value={shutterSpeed}
                                >
                                    <option value='1/2000' >1/2000</option>
                                    <option value='1/1000'>1/1000</option>
                                    <option value='1/500' >1/500</option>
                                    <option value='1/250' >1/250</option>
                                    <option value='1/125' >1/125</option>
                                    <option value='1/60' >1/60</option>
                                    <option value='1/30' >1/30</option>
                                    <option value='1/15' >1/15</option>
                                    <option value='1/8' >1/8</option>
                                    <option value='1/4' >1/4</option>
                                    <option value='1/2' >1/2</option>
                                    <option value='1/1' >1/1</option>
                                    <option value='2' >2</option>
                                    <option value='4' >4</option>

                                </select>

                                <label htmlFor='f-stop'>F-Stop:</label>
                                {fStop}
                                <input 
                                    type='range'
                                    value={fStop} 
                                    name='f-stop' 
                                    id='f-stop' 
                                    list='f-stop-markers'
                                    min='1'
                                    max='22'
                                    step='0.1'
                                    onChange={(e) => setFStop(e.target.value)}
                                />
                                <datalist id='f-stop-markers'>
                                    <option value='1.2' ></option>
                                    <option value='2' ></option>
                                    <option value='2.8'></option>
                                    <option value='4' ></option>
                                    <option value='5.6' ></option>
                                    <option value='8' ></option>
                                    <option value='11' ></option>
                                    <option value='16' ></option>
                                    <option value='22' ></option>
                                </datalist>

                                <label htmlFor='exposure-comp'>Exposure Comp:</label>
                                {exposureComp}
                                <input 
                                    type='range'
                                    value={exposureComp}
                                    name='exposure-comp'
                                    id='exposure-comp'
                                    list='exposure-comp-markers'
                                    min='-4'
                                    max='4'
                                    step='0.25'
                                    onChange={(e) => setExposureComp(e.target.value)}
                                />
                                <datalist id='exposure-comp-markers'>
                                    <option value='-4'></option>
                                    <option value='-2'></option>
                                    <option value='1'></option>
                                    <option value='2'></option>
                                    <option value='4'></option>
                                </datalist>

                                {/* <fieldset 
                                    onChange={(e) => {
                                        let word = e.target.value
                                        const s = word.charAt(0).toUpperCase() + word.slice(1)
                                        setShootingMode(s)
                                    }}
                                    value={shootingMode}
                                >
                                    <legend>Shooting Mode: {shootingMode}</legend>

                                    <div>
                                        <input type="radio" name="mode" value="auto"/>
                                        <label htmlFor="auto">Auto</label>
                                    </div>

                                    <div>
                                        <input type="radio" name="mode" value="manual"/>
                                        <label htmlFor="manual">Manual</label>
                                    </div>

                                    <div>
                                        <input type="radio" name="mode" value="125X"/>
                                        <label htmlFor="125X">125X</label>
                                    </div>

                                    <div>
                                        <input type="radio" name="mode" value="B"/>
                                        <label htmlFor="B">B</label>
                                    </div>
                                </fieldset> */}

                                <input 
                                    type='submit'
                                    value='Submit'
                                />     
                            </form>                                
                        )
                    }
                    return ''
                })
            ) : ''}
    </div>
  )
}

export default EditPhoto