// ==============BOILERPLATE IMPORTS==================== //
import { useEffect, useState } from 'react';
import './App.css';

// ==============PACKAGE METHOD IMPORTS==================== //
import {auth, db, storage} from './firebaseConfig.js'
import { doc, onSnapshot, setDoc, updateDoc, collection, getDocs, getDoc, deleteDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { EmailAuthProvider, createUserWithEmailAndPassword, deleteUser, reauthenticateWithCredential, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


// ==============COMPONENT IMPORTS==================== //
import Header from './components/Header/Header';
import ManageRoll from './components/ManageRoll/ManageRoll';
import SelectRoll from './components/SelectRoll/SelectRoll';
import Navbar from './components/Navbar/Navbar';
import SelectPhoto from './components/SelectPhoto/SelectPhoto';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import Settings from './components/Settings/Settings';
import Home from './components/Home/Home';
import AddRoll from './components/AddRoll/AddRoll';
import AddPhoto from './components/AddPhoto/AddPhoto';
import AddCamera from './components/AddCamera/AddCamera';
import SuccessMessage from './components/SuccessMessage/SuccessMessage';
import AddLens from './components/AddLens/AddLens';
import DeleteAccount from './components/Settings/DeleteAccount/DeleteAccount';

// ==============REDUCER IMPORTS==================== //
import { 
  setData,
  selectData,
} from './reducers/AppSlice';
import { 
  selectToggleDeleteAccount,
  selectToggleLoginError, 
  selectUserId,
  setIsAdmin,
  setToggleLoginError, 
  setUserId, 
} from './reducers/userSlice';
import { 
  selectAddCameraActive, 
  selectAddLensActive, 
  selectCurrentCameraId, 
  setAddCameraActive, 
  setAddLensActive, 
  setCurrentCameraId,
} from './reducers/cameraSlice';
import { 
  setMessage,
  selectToggleMessage,
  setToggleMessage,
} from './reducers/messageSlice';
import { 
  selectAddPhotoActive, 
  selectCurrentPhotoId, 
  setAddPhotoActive, 
  setCurrentPhotoId,
  setUploadProgress,
} from './reducers/photoSlice';
import { 
  selectAddRollActive, 
  selectCurrentRollId, 
  setAddRollActive, 
  setCurrentRollId,
} from './reducers/rollSlice';

import { createDateString } from './helpers';



function App() {
  
  const dispatch = useDispatch()
  
  const data = useSelector(selectData)
  const userId = useSelector(selectUserId)
  const toggleLoginError = useSelector(selectToggleLoginError)
  const toggleDeleteAccount = useSelector(selectToggleDeleteAccount)

  const currentCameraId = useSelector(selectCurrentCameraId)
  const addCameraActive = useSelector(selectAddCameraActive)
  const addLensActive = useSelector(selectAddLensActive)

  const toggleMessage = useSelector(selectToggleMessage)
  const addPhotoActive = useSelector(selectAddPhotoActive)
  
  const currentRollId = useSelector(selectCurrentRollId)
  const addRollActive = useSelector(selectAddRollActive)

  const currentPhotoId = useSelector(selectCurrentPhotoId)

  
  const [toggleAdd, setToggleAdd] = useState(false)
  const [activeIcon, setActiveIcon] = useState('cameraRoll')
  
  const [searchParams] = useSearchParams()
    
  const navigate = useNavigate()

  
  // ==============CHECK LOCAL STORAGE FOR USER DATA==================== //
  useEffect(() => {
    const userDataFetch = JSON.parse(localStorage.getItem('user'))

    if (userDataFetch) {
      dispatch(setUserId(userDataFetch.uid))
    }
  }, [dispatch])


  // ==============FETCH DATA FROM FIRESTORE DB==================== //
  useEffect(() => {
    const fetchData = () => {
      const unsub = onSnapshot(doc(db, 'users', userId), (doc) => {
        
        // console.log('data from db: ', doc.data())
          dispatch(setData(doc.data().cameras))
          dispatch(setIsAdmin(doc.data().is_admin))
          // setTestData(doc.data().cameras)
          if (doc.data().cameras && doc.data().cameras.length > 0) {
            // dispatch(setCurrentCameraId(doc.data().cameras[0].id))
            // if (doc.data().cameras[0].film_rolls.length > 0) {
            //   if (doc.data().cameras[0].film_rolls[0].photos.length > 0) {
            //     dispatch(setCurrentPhotoId(doc.data().cameras[0].film_rolls[0].photos[0].id))
            //   }
            // }
          }
        
      })
      return unsub
    }
    
    if (userId) {
      fetchData()
    }
    
  },[userId, dispatch])

  
  // ==============CHECK URL FOR CURRENT ROLL ID==================== //
  useEffect(() => {
    const rollId = searchParams.get('roll_id')
    if (rollId) {
      dispatch(setCurrentRollId(Number(rollId)))
    }

    const cameraId = searchParams.get('camera_id')
    if (cameraId) {
      dispatch(setCurrentCameraId(Number(cameraId)))
    }
  },[searchParams, dispatch])



  
  // ==============CREATE DB BACKUP==================== //
  const handleCreateDBBackup = async () => {
    const dateCreated = createDateString()
    
    const querySnapshot = await getDocs(collection(db, "users"));
    querySnapshot.forEach(async (docu) => {

      const tempObj = {...docu.data(), backup_date: dateCreated}

      const ref = doc(db, '__users_backup', docu.id)
      await updateDoc(ref, tempObj)
    });
    handleMessage('DB backed up successfully!')
  }
  
  // ==============CHANGE PHOTO URLS FOR CURRENT ROLL TO EDITED SCANS==================== //
  const handleChangePhotoUrls = async (rollId) => {
    if (data) {
      
      const tempData = JSON.parse(JSON.stringify(data))
      await Promise.all(tempData.map(async (camera) => {
        if (camera.id === currentCameraId) {
          await Promise.all(camera.film_rolls.map(async (roll) => {
            if (roll.id === rollId) {

              const failedUrl = `https://firebasestorage.googleapis.com/v0/b/film-catalog-b8470.appspot.com/o/qp4DPnX0FTMIx1ZVhHR7sGcnFMw1%2F38432%2F30135%2Ffailed.JPEG?alt=media&token=56718ec7-de52-414c-9453-5d22cc565ec1`
              
              await Promise.all(roll.photos.map(async (photo) => {
                if (photo.subject.toLowerCase().includes('failed') || photo.subject.toLowerCase().includes('unknown')) {
                 photo.url = failedUrl
                } else {
                  // photo.url = `https://firebasestorage.googleapis.com/v0/b/film-catalog-b8470.appspot.com/o/qp4DPnX0FTMIx1ZVhHR7sGcnFMw1%2F${camera.id}%2F${rollId}%2F${photo.id}.JPEG?alt=media&token=b45b222c-b86b-4d07-b3cc-5e9f315f6cf4`
                  const storage = getStorage();

                  
                  // const httpsReference = ref(storage, `https://firebasestorage.googleapis.com/b/bucket/o/${userId}%2F${camera.id}%2F${roll.id}%2F${photo.id}.JPEG`);
                  const httpsReference = ref(storage, `https://firebasestorage.googleapis.com/v0/b/film-catalog-b8470.appspot.com/o/${userId}%2F${camera.id}%2F${roll.id}%2F${photo.id}.JPEG`);
                  // console.log(httpsReference)
                  photo.url = await getDownloadURL(httpsReference)
                  // console.log(photo.url)
                }
              }))
            }
          }))
        }
      }))

      // console.log('tempData: ', tempData)

      try {
        const docRef = doc(db, 'users', userId)
        await updateDoc(docRef, {
          cameras: tempData
        })
        handleMessage('URL\'s Updated!')
      } catch (e) {
        console.log(e.message)
      }
    }
  }
  // ==============UPLOAD IMAGE HANDLER==================== //
  const handleUploadImage = async (imageUpload) => {
    if (imageUpload === null) return

    const imageRef = ref(storage, `${userId}/${currentCameraId}/${currentRollId}/${currentPhotoId}.JPEG`)

    // Create the file metadata
    /** @type {any} */
    // const metadata = {
    //   contentType: 'image/jpeg'
    // };

    // Upload file and metadata to the object 'images/mountains.jpg'
    const uploadTask = uploadBytesResumable(imageRef, imageUpload);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        dispatch(setUploadProgress(progress))
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      }, 
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      }, 
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          console.log('File available at', downloadURL);

          if (data) {
          
            const tempData = JSON.parse(JSON.stringify(data))
            await Promise.all(tempData.map(async (camera) => {
              if (camera.id === currentCameraId) {
                await Promise.all(camera.film_rolls.map(async (roll) => {
                  if (roll.id === currentRollId) {
                    await Promise.all(roll.photos.map(async (photo) => {
                      if (photo.id === currentPhotoId){
                        const httpsReference = ref(storage, `https://firebasestorage.googleapis.com/v0/b/film-catalog-b8470.appspot.com/o/${userId}%2F${camera.id}%2F${roll.id}%2F${photo.id}.JPEG`);
                        photo.url = await getDownloadURL(httpsReference)
                        dispatch(setCurrentPhotoId(photo.id))
                      } 
                    }))
                  }
                }))
              }
            }))
        
            try {
              const docRef = doc(db, 'users', userId)
              await updateDoc(docRef, {
                cameras: tempData
              })
              handleMessage('Photo Uploaded!')
            } catch (e) {
              console.log(e.message)
            }
          }

        });
      }
    );

    // uploadBytes(imageRef, imageUpload)
    // .then(async () => {
    //   setTogglePhotoModal(false)
    //   setCurrentPhotoId(currentPhotoId)
    // })
  }

  

  // ==============SIGN UP AND LOG IN NEW USER==================== //
  const handleSignUpNewUser = async (e, email, password) => {
    e.preventDefault()

    await createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
            // Signed in
            const user = userCredential.user;
            const newUserDocRef = doc(db, 'users', user.uid)
            await setDoc(newUserDocRef, {
              'cameras': [],
              'is_admin': false,
              'date_created': createDateString(),
            })
            dispatch(setUserId(user.uid))
            navigate(`/select-roll?user_id=${user.uid}&camera_id=${0}&roll_id=${0}`)
            localStorage.setItem('user', JSON.stringify(user))
            

        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
        // ..
    });
 }

 // ==============LOGIN EXISTING USER==================== //
  const handleLoginUser = (e, email, password) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        dispatch(setUserId(user.uid))
        localStorage.setItem('user', JSON.stringify(user))
        handleNavigate(`/select-roll`)

    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
        dispatch(setToggleLoginError(!toggleLoginError))

    });
   
}

  // ==============SIGN OUT==================== //
  const handleSignout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
      navigate("/");
      localStorage.removeItem('user')
      dispatch(setUserId(null))
      dispatch(setData([]))
      window.location.reload()
      console.log("Signed out successfully")
    }).catch((error) => {
      // An error happened.
      console.log(error)
    });
  }

  // ==============DELETE USER ACCOUNT==================== //
  const handleDeleteAccount = async (e, email, password) => {
    e.preventDefault()

    //move user data to __deleted_users
    const userDocRef = doc(db, 'users', userId)
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      console.log("Document data:", userDocSnap.data());
      await setDoc(doc(db, '__deleted_users', userId), userDocSnap.data())
      await setDoc(userDocRef, {})
      await deleteDoc(userDocRef)
    }   


    // delete user in firebase
    const user = auth.currentUser;

    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    )

    reauthenticateWithCredential(user, credential).then(() => {
      // User re-authenticated.
      
          deleteUser(user).then(() => {
            // User deleted.
            localStorage.removeItem('user')
            dispatch(setUserId(null))
            dispatch(setData(
              {
                cameras: [],
                is_admin: false
              }
            ))
            console.log("Deleted Account Successfully")
          }).then(() => {
            navigate("/");
          }).then(() => {
            window.location.reload()
          }).catch((error) => {
            // An error ocurred
            console.log('Error deleting user: ', error.message)
          });
    }).catch((error) => {
      // An error ocurred
      // ...
      console.log('Error: ', error.message)
    });
  }




  // ==============MESSAGE HANDLER==================== //
  const handleMessage = (message) => {
    dispatch(setMessage(message))
    dispatch(setToggleMessage(true))
    setTimeout(() => {
      dispatch(setToggleMessage(false))
    }, 3000)
  }



  
  // ==============NAVIGATION HANDLER==================== //
  const handleNavigate = (page) => {
    navigate(`${page}?user_id=${userId}&roll_id=${currentRollId}`)
  }
  
  // ==============CHANGE CURRENT ROLE AND NAVIGATE==================== //
  const handleChangeRollAndNavigate = (page, cameraId, rollId) => {
    setToggleAdd(false)
    dispatch(setCurrentRollId(Number(rollId)))
    dispatch(setCurrentCameraId(Number(cameraId)))
    navigate(`${page}?user_id=${userId}&camera_id=${Number(cameraId)}&roll_id=${Number(rollId)}`)
  }

  return (
    <div className="App">
      {
        // ==============HEADER==================== //
      }
      <Header 
        navigate={navigate}
        setActiveIcon={setActiveIcon}
      />
      {
        // ==============ROUTES / MAIN SECTION==================== //
      }
      {data ? (
        <Routes>
          <Route path='/' element={
            <Home 
              handleChangeRollAndNavigate={handleChangeRollAndNavigate}
            />
          }></Route>
          <Route path='/login' element={
            <Login 
              handleLoginUser={handleLoginUser}
            />
          }></Route>
          <Route path='/sign-up' element={
            <SignUp 
            handleSignUpNewUser={handleSignUpNewUser}
            />
          }></Route>
          <Route path={'/select-roll'} 
          element={
            <SelectRoll 
              handleMessage={handleMessage}
              handleChangeRollAndNavigate={handleChangeRollAndNavigate}
              setActiveIcon={setActiveIcon}
            />
          }
          ></Route>
          <Route path='/select-photo' 
          element={
            <SelectPhoto 
              handleChangeRollAndNavigate={handleChangeRollAndNavigate}
              setToggleAdd={setToggleAdd}
              handleMessage={handleMessage}
              handleUploadImage={handleUploadImage}
              />
            }
          ></Route>
          <Route 
            path='/settings' element={
              <Settings 
                handleChangeRollAndNavigate={handleChangeRollAndNavigate}
                handleSignout={handleSignout}
                handleCreateDBBackup={handleCreateDBBackup}
              />
            }
          ></Route>
          <Route path='/manage-roll' element={
            <ManageRoll 
              handleChangeRollAndNavigate={handleChangeRollAndNavigate}
              handleSignout={handleSignout}
              handleMessage={handleMessage}
              handleChangePhotoUrls={handleChangePhotoUrls}
              handleUploadImage={handleUploadImage}
              />
            }></Route>
        </Routes>
      ) : (
        <h3 style={{color: 'white'}}>loading...</h3>
      )}
      {
        // ==============CONDITIONAL MODALS==================== //
      }
      {addCameraActive ? (
        <div>
          <div 
            className={`add-camera-container ${addCameraActive ? 'active': ''}`}
            onClick={() => {
              dispatch(setAddCameraActive(false))
            }}
          >
          </div>
          <AddCamera 
            handleMessage={handleMessage} 
          />
        </div>
      ) : ''}
      {addRollActive ? (
        <div>
          <div
            className={`add-roll-container ${addRollActive ? 'active' : ''}`}
            onClick={() => {
              dispatch(setAddRollActive(false))
            }}
          >
          </div>
          <AddRoll 
            handleMessage={handleMessage} 
          />

        </div>
      ) : ''}
      {addLensActive ? (
        <div>
          <div
            className={`add-lens-container ${addLensActive ? 'active' : ''}`}
            onClick={() => {
              dispatch(setAddLensActive(false))
            }}
          >
          </div>
          <AddLens 
            handleMessage={handleMessage}
          />
        </div>
      ) : ''}
      {addPhotoActive ? (
        <div>
          <div
            className={`add-photo-container ${addPhotoActive ? 'active' : ''}`}
            onClick={() => {
              dispatch(setAddPhotoActive(false))
            }}
          >
          </div>
          <AddPhoto 
            handleMessage={handleMessage} 
          /> 
        </div>
        )
      : ''}
      {toggleMessage ? (
        <SuccessMessage />
      ) : ''}
      {toggleDeleteAccount ? (
        <DeleteAccount 
          handleDeleteAccount={handleDeleteAccount}
        />
      ) : ''}
      {
        // ==============NAVBAR==================== //
      }
      {userId ? (
        <Navbar 
          handleChangeRollAndNavigate={handleChangeRollAndNavigate}
          toggleAdd={toggleAdd}
          setToggleAdd={setToggleAdd}
          activeIcon={activeIcon} 
          setActiveIcon={setActiveIcon}
        />
      ) : ''}
    </div>
  );
}

export default App;
