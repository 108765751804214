import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    userId: null,
    toggleLoginError: false,
    isAdmin: false,
    toggleDeleteAccount: false,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserId: (state, action) => {
            state.userId = action.payload
        },
        setToggleLoginError: (state, action) => {
            state.toggleLoginError = action.payload
        },
        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload
        },
        setToggleDeleteAccount: (state, action) => {
            state.toggleDeleteAccount = action.payload
        },
    }
})

export const {
    setUserId,
    setToggleLoginError,
    setIsAdmin,
    setToggleDeleteAccount
} = userSlice.actions

export const selectUserId = (state) => state.user.userId
export const selectToggleLoginError = (state) => state.user.toggleLoginError
export const selectIsAdmin = (state) => state.user.isAdmin
export const selectToggleDeleteAccount = (state) => state.user.toggleDeleteAccount

export default userSlice.reducer