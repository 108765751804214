import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    currentPhotoId: 1,
    addPhotoActive: false,
    toggleEditPhoto: false,
    togglePhotoModal: false,
    toggleDeletePhoto: false,
    uploadProgress: 0,
}

const photoSlice = createSlice({
    name: 'photo',
    initialState,
    reducers: {
        setCurrentPhotoId: (state, action) => {
            state.currentPhotoId = action.payload
        },
        setAddPhotoActive: (state, action) => {
            state.addPhotoActive = action.payload
        },
        setToggleEditPhoto: (state, action) => {
            state.toggleEditPhoto = action.payload
        },
        setTogglePhotoModal: (state, action) => {
            state.togglePhotoModal = action.payload
        },
        setToggleDeletePhoto: (state, action) => {
            state.toggleDeletePhoto = action.payload
        },
        setUploadProgress: (state, action) => {
            state.uploadProgress = action.payload
        }
    },
})

export const {
    setCurrentPhotoId,
    setAddPhotoActive,
    setToggleEditPhoto,
    setTogglePhotoModal,
    setToggleDeletePhoto,
    setUploadProgress,
} = photoSlice.actions

export const selectCurrentPhotoId = (state) => state.photo.currentPhotoId
export const selectAddPhotoActive = (state) => state.photo.addPhotoActive
export const selectToggleEditPhoto = (state) => state.photo.toggleEditPhoto
export const selectTogglePhotoModal = (state) => state.photo.togglePhotoModal
export const selectToggleDeletePhoto = (state) => state.photo.toggleDeletePhoto
export const selectUploadProgress = (state) => state.photo.uploadProgress

export default photoSlice.reducer